import { createStore } from 'vuex'
import copyJson from '../data/copy.json'
import placesJson from '../data/places.json'
import categoriesJson from '../data/categories.json'
import imagesJson from '../data/images.json'
import topicsJson from '../data/topics.json'
import sourcesJson from '../data/sources.json'

export default createStore({
	state: {
		copy: copyJson,
		places: placesJson,
		categories: categoriesJson,
		images: imagesJson,
		topics: topicsJson,
		sources: sourcesJson,
		app: {
			language: 'cz',
			view: 'map',
			map: {
				active: null,
				opened: false
			},
			list: {
				active: null,
			}
		}
	},
	mutations: {
		setLanguage(state, language) {
			state.app.language = language;
		},
		changeActive(state, object) {
			state.app.map.active = object
		},
		openWindow(state) {
			state.app.map.opened = true
		},
		closeWindow(state) {
			state.app.map.opened = false
		}
	},
	actions: {
	},
	modules: {
	}
})
