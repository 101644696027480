<template>
    <div class="app">
        <nav class="app__navigation">
            <div class="app__navigation__logo">
                <div class="app__navigation__logo__circle">
                    <img
                        class="app__navigation__logo__image"
                        src="./assets/logo.png"
                    />
                </div>
            </div>
            <ul class="app__navigation__list">
                <li class="app__navigation__item">
                    <router-link to="/">{{ $t('navigation.map') }}</router-link>
                </li>
                <li class="app__navigation__item">
                    <router-link to="/topics">{{ $t('navigation.topics') }}</router-link>
                </li>
            </ul>
            <ul class="app__navigation__languages">
                <li class="app__navigation__language">
                    <input id="language-cz" type="radio" name="language" value="cz" checked v-model="$i18n.locale" />
                    <label for="language-cz" class="app__navigation__language__label">CZ</label>
                </li>
                <li class="app__navigation__language">
                    <input id="language-de" type="radio" name="language" value="de" v-model="$i18n.locale" />
                    <label for="language-de" class="app__navigation__language__label">DE</label>
                </li>
                <li class="app__navigation__language">
                    <input id="language-en" type="radio" name="language" value="en" v-model="$i18n.locale" />
                    <label for="language-en" class="app__navigation__language__label">EN</label>
                </li>
            </ul>
        </nav>
        <main class="app__content">
            <router-view />
        </main>
    </div>
</template>

<style lang="scss">
.app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color-grey-400;
    width: 100%;
    overflow: hidden;

    &__navigation {
        @include base-title;
        height: 80px;
        line-height: 20px;
        position: fixed;
        bottom: -1px;
        left: 0;
        right: 12px;
        z-index: 99;
        overflow: hidden;
        align-items: center;
        flex-direction: row;
        display: flex;
        background: linear-gradient(to bottom, rgba(253,237,231,0) 0%,rgba(253,237,231,1) 100%);

        @include media-breakpoint-up(lg) {
            height: 100px;
        }

        @include media-breakpoint-up(2xl) {
            height: 120px;
        }

        &__logo {
            padding: 0 $base-gap;

            @include media-breakpoint-up(2xl) {
                padding: $base-gap * 2 $base-gap * 5;
            }

            &__circle {
                width: 80px;
                height: 80px;
                border-radius: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            img {
                width: 60px;
                display: block;
                height: auto;

                @include media-breakpoint-up(lg) {
                    width: 60px;
                }

                @include media-breakpoint-up(2xl) {
                    width: 80px;
                }
            }
        }

        &__list {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            border-radius: 10px;
            overflow: hidden;
        }

        &__item {
            a {
                text-decoration: none;
                display: block;
                padding: $base-gap * 1.5 $base-gap * 2;
                text-transform: uppercase;
                font-size: 16px;
                letter-spacing: 1px;
                font-weight: 700;
                background-color: #3c3c3c;
                color: #fff;
                min-width: 165px;
                text-align: center;

                @include media-breakpoint-up(2xl) {
                    font-size: 24px;
                    padding: $base-gap * 2 $base-gap * 4 $base-gap * 1.8;
                }

                &.router-link-active {
                    background-color: $color-red-400;
                }
            }
        }

        &__languages {
            display: flex;
            border-radius: 5px;
            overflow: hidden;
            margin-left: $base-gap * 5;
        }

        &__language {
            [type="radio"] {
                position: absolute;
                left: -9999px;
            }

            label {
                padding: $base-gap $base-gap * 2;
                display: block;
                cursor: pointer;
                background-color: #3c3c3c;
                color: #fff;
            }

            [type="radio"]:checked + label {
                background-color: $color-red-400;
            }
        }
    }

    &__content {
        background-color: $color-red-100;
    }
}

.text-content {
    line-height: 1.6em;
    color: rgb(60, 60, 60);
    font-weight: 500;
}
</style>
