<template>
    <div class="place">
        <div class="place__container">
            <header class="place__header">
                <h2 class="place__topic">{{ topic.id }}. <span v-html="topic.label[this.$i18n.locale]"></span></h2>
                <h1 class="place__title">{{ place['name-cz'] }}</h1>
                <h2 class="place__subtitle">{{ place['name-de'] }}</h2>
            </header>
            <div class="place__images">
                <div class="place__image" v-for="image in images" :key="image">
                    <div class="place__image__box">
                        <img :src="getImgUrl( image.name )" />
                    </div>
                    <div class="place__image__label">
                        {{ image['label-' + this.$i18n.locale] }}
                    </div>
                </div>
            </div>
            <div class="place__sources">
                <h3 class="place__sources__title">{{ $t('sources.title') }}</h3>
                <ul class="place__sources__list">
                    <li class="place__sources__item" v-for="source in sources" :key="source">
                        {{ source['label'][this.$i18n.locale] }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="place__jump" @click="jumpTop()">
            <span>&lt;</span>
        </div>
        <span class="place__back" @click="$router.go(-1)">&lt; {{ $t('places.back') }}</span>
    </div>
</template>

<script>
export default {
    name: 'Place',
    props: ['topicId', 'placeId'],
    data() {
        return {
        };
    },
    computed: {
        topics() {
            return this.$store.state.topics;
        },
        sources() {
            return this.$store.state.sources;
        },
        topic() {
            const topic = this.topics.find( ( item ) => {
                return item.id == this.topicId;
            } );

            return topic;
        },
        place() {
            const place = this.$store.state.places.find( ( item ) => {
                return item.id == this.placeId;
            } );

            return place;
        },
        images() {
            const images = this.$store.state.images.filter( ( item ) => {
                let topics = item.topics.split('|');
                return ( item.place_id == this.placeId ) && topics.indexOf(this.topicId) != -1;
            } );

            return images;
        }
    },
    mounted() {
        const jumpTop = document.querySelector( '.place__jump' );
        let lastKnownScrollPosition = 0;
        let ticking = false;

        document.addEventListener('scroll', function(e) {
            lastKnownScrollPosition = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function() {
                    if ( lastKnownScrollPosition > 1080 ) {
                        jumpTop.classList.add( 'visible' );
                    } else {
                        jumpTop.classList.remove( 'visible' );
                    }

                    ticking = false;
                });

                ticking = true;
            }
        });
    },
    unmounted() {},
    methods: {
        jumpTop() {
            window.scrollTo( 0, 0 );
        },
        getImgUrl( name ) {
            if (process.env.NODE_ENV === 'production' && process.env.IS_ELECTRON) {
                return `file://${__dirname}/images/${name}.jpg`
            } else {
                return `images/${name}.jpg`;
            }
        }
    },
};
</script>

<style lang="scss">
.place {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: $base-gap * 5 $base-gap * 12 $base-gap * 16;
    display: flex;
    justify-content: flex-end;
    min-height: 100vh;

    &__container {
        max-width: 1000px;
        width: 100%;
    }

    &__header {
        margin-bottom: $base-gap * 3;
    }

    &__topic {
        @include micro-title;
        color: $color-grey-400;
        margin-bottom: $base-gap * 2;
    }

    &__title {
        @include title;
        text-transform: uppercase;
        color: $color-red-400;
        margin-left: -3px;
    }

    &__subtitle {
        @include micro-title;
        text-transform: uppercase;
        color: $color-grey-400;
    }

    &__image {
        &__box {
            width: 100%;

            img {
                max-width: 100%;
                height: auto;
                width: auto;
                display: block;
                max-height: 900px;
            }
        }

        &__label {
            font-size: 16px;
            font-weight: 500;
            margin-top: $base-gap;
            color: #3c3c3c;
            line-height: 1.4em;
        }
    }

    &__image + &__image {
        margin-top: $base-gap * 4;
    }

    &__back {
        @include mini-title;
        position: fixed;
        top: $base-gap * 6;
        left: $base-gap * 5;
        cursor: pointer;
        color: $color-red-400;
    }

    &__jump {
        @include base-title;
        color: $color-red-400;
        font-weight: 500;
        position: fixed;
        top: $base-gap * 4.5;
        right: $base-gap * 5.5;
        z-index: 999;
        font-size: 72px;
        transform: rotate(90deg);
        cursor: pointer;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 500ms;

        &.visible {
            opacity: 1;
        }
    }
}
</style>
