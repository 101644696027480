<template>
    <div class="topics">
        <div class="topics__container">
            <header class="topics__header">
                <h1 class="topics__title" v-html="title[this.$i18n.locale]"></h1>
            </header>
            <div class="topics__content">
                <ol class="topics__list">
                    <li
                        class="topics__item"
                        v-for="topic of topics"
                        :key="topic.id"
                    >
                        <router-link
                            :to="{ name: 'topic', params: { topicId: topic.id } }"
                            ><span class="topics__item__number">{{ topic.id }}. </span><strong v-html="topic.label[this.$i18n.locale]"></strong></router-link
                        >
                    </li>
                </ol>
            </div>
            <div class="topics__text">
                <div class="text-content" v-html="text[this.$i18n.locale]"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Topics',
    props: ['opened'],
    data() {
        return {};
    },
    computed: {
        topics() {
            return this.$store.state.topics;
        },
        title: function() {
            return this.$store.state.copy.topics.title;
        },
        text: function() {
            return this.$store.state.copy.topics.text;
        }
    },
    mounted() {},
    unmounted() {},
    methods: {}
};
</script>

<style lang="scss">
.topics {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: $base-gap * 5 $base-gap * 12 $base-gap * 16;
    display: flex;
    justify-content: flex-end;
    min-height: 100vh;

    &__container {
        width: 100%;
        max-width: 1000px;
    }

    &__header {
        margin-bottom: $base-gap * 4;
    }

    &__title {
        @include title;
        margin: 0;
        text-transform: uppercase;
        color: $color-red-400;
    }

    &__item {
        @include micro-title;
        position: relative;
        margin-left: 1.1em;

        &__number {
            position: absolute;
            left: -1em;
            color: $color-red-400;
        }

        a {
            color: rgb(60, 60, 60);
            text-transform: uppercase;
        }
    }

    &__item + &__item {
        margin-top: $base-gap * 2;
    }

    &__text {
        margin-top: $base-gap * 4;
    }
}
</style>
