<template>
    <div class="topic">
        <div class="topic__container">
            <header class="topic__header">
                <h1 class="topic__title">{{ topic.id }}. <span v-html="topic.label[this.$i18n.locale]"></span></h1>
            </header>
            <div class="topic__content">
                <ul class="topic__places">
                    <li class="topic__place" v-for="place of topicPlaces" :key="place.id">
                        <router-link :to="{ name: 'topic-place', params: { topicId: topic.id, placeId: place.id } }">{{ place['name-cz'] }}</router-link>
                    </li>
                </ul>
            </div>
            <div class="topic__text">
                <div class="text-content" v-html="topic.text[this.$i18n.locale]"></div>
            </div>
            <div class="topic__images" v-if="topicId == 5 || topicId == 6">
                <div class="topic__image" v-for="image in images" :key="image">
                    <div class="topic__image__box">
                        <img :src="getImgUrl( image.name )" />
                    </div>
                    <div class="topic__image__label">
                        {{ image['label-' + this.$i18n.locale] }}
                    </div>
                </div>
            </div>
            <div class="topic__model" v-if="topicId == 7">
                <div class="topic__model__video">
                    <video width="320" height="240" controls autoplay muted>
                        <source :src="getVideoUrl( 'model-synagoga' )" type="video/mp4">
                    </video>
                </div>
                <div class="topic__model__label">
                    {{ 'Ing. Ivan Klíma' }}
                </div>
            </div>
            <div class="topic__sources" v-if="topicId == 5 || topicId == 6">
                <h3 class="topic__sources__title">{{ $t('sources.title') }}</h3>
                <ul class="topic__sources__list">
                    <li class="topic__sources__item" v-for="source in sources" :key="source">
                        {{ source['label'][this.$i18n.locale] }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="topic__jump" @click="jumpTop()">
            <span>&lt;</span>
        </div>
        <span class="topic__back" @click="$router.back()">&lt; {{ $t('topics.back') }}</span>
    </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

export default {
    name: 'Mapbox',
    props: ['topicId'],
    data() {
        return {};
    },
    computed: {
        topics() {
            return this.$store.state.topics;
        },
        sources() {
            return this.$store.state.sources;
        },
        places() {
            return this.$store.state.places;
        },
        topic() {
            const topic = this.topics.find( ( item ) => {
                return item.id == this.topicId;
            } );

            return topic;
        },
        topicPlaces() {
            const places = this.places.filter( ( item ) => {
                let topics = item.topics.split('|');
                return topics.indexOf(this.topicId) != -1;
            } );

            return places;
        },
        images() {
            const images = this.$store.state.images.filter( ( item ) => {
                let topics = item.topics.split('|');
                return topics.indexOf(this.topicId) != -1;
            } );

            return images;
        }
    },
    mounted() {
        const iconUrlVar = require('../assets/plyr.svg' );
        const player = new Plyr('video', {
            controls: ['play-large', 'play', 'progress', 'current-time'],
            loadSprite: false,
            iconUrl: iconUrlVar,
            fullscreen: {
                enabled: false
            }
        });
        
        const jumpTop = document.querySelector( '.topic__jump' );
        let lastKnownScrollPosition = 0;
        let ticking = false;

        document.addEventListener('scroll', function(e) {
            lastKnownScrollPosition = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function() {
                    if ( lastKnownScrollPosition > 1080 ) {
                        jumpTop.classList.add( 'visible' );
                    } else {
                        jumpTop.classList.remove( 'visible' );
                    }

                    ticking = false;
                });

                ticking = true;
            }
        });
    },
    unmounted() {},
    methods: {
        jumpTop() {
            window.scrollTo( 0, 0 );
        },
        getImgUrl( name ) {
            if (process.env.NODE_ENV === 'production' && process.env.IS_ELECTRON) {
                return `file://${__dirname}/images/${name}.jpg`
            } else {
                return `images/${name}.jpg`;
            }
        },
        getVideoUrl( url ) {
            if (process.env.NODE_ENV === 'production' && process.env.IS_ELECTRON) {
                return `file://${__dirname}/videos/${url}.mp4`
            } else {
                return `videos/${url}.mp4`;
            }
        },
    },
};
</script>

<style lang="scss">
.topic {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: $base-gap * 5 $base-gap * 12 $base-gap * 16;
    min-height: 100vh;
    display: flex;
    justify-content: flex-end;

    &__container {
        width: 100%;
        max-width: 1000px;
    }

    &__header {
        margin-bottom: $base-gap * 4;
    }

    &__title {
        @include title;
        margin: 0;
        text-transform: uppercase;
        color: $color-red-400;
    }

    &__places {
        column-count: 3;
    }

    &__place {
        @include base-title;
        color: rgb(60, 60, 60);
        text-transform: uppercase;
        font-size: 24px;
        margin-bottom: 10px;
    }

    &__sources {
        position: fixed;
        bottom: $base-gap * 16;
        left: $base-gap * 5;

        &__title {
            @include base-title;
            color: $color-red-400;
            margin-bottom: $base-gap;
            font-weight: 500;
        }

        &__item {
            font-size: 14px;
            font-weight: 500;
        }

        &__item + &__item {
            margin-top: $base-gap * 0.5;
        }
    }

    &__text {
        margin-top: $base-gap * 4;
    }

    &__back {
        @include mini-title;
        position: fixed;
        top: $base-gap * 6;
        left: $base-gap * 5;
        cursor: pointer;
        color: $color-red-400;
    }

    &__images {
        margin-top: $base-gap * 5;
    }

    &__image {
        &__box {
            width: 100%;

            img {
                max-width: 100%;
                height: auto;
                width: auto;
                display: block;
                max-height: 900px;
            }
        }

        &__label {
            font-size: 16px;
            font-weight: 500;
            margin-top: $base-gap;
            color: #3c3c3c;
            line-height: 1.4em;
        }
    }

    &__image + &__image {
        margin-top: $base-gap * 4;
    }

    &__model {
        &__video {
            background-color: $color-grey-400;

            video {
                max-width: 100%;
                height: auto;
                width: auto;
                display: block;
                max-height: 900px;
                width: 1000px;
                height: 562px;
            }
        }

        &__label {
            font-size: 16px;
            font-weight: 500;
            margin-top: $base-gap;
            color: #3c3c3c;
            line-height: 1.4em;
        }
    }

    &__jump {
        @include base-title;
        color: $color-red-400;
        font-weight: 500;
        position: fixed;
        top: $base-gap * 4.5;
        right: $base-gap * 5.5;
        z-index: 999;
        font-size: 72px;
        transform: rotate(90deg);
        cursor: pointer;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 500ms;

        &.visible {
            opacity: 1;
        }
    }
}
</style>
