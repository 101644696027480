<template>
    <div class="place">
        <div class="place__container">
            <header class="place__header">
                <h1 class="place__title">{{ place['name-cz'] }}</h1>
                <h2 class="place__subtitle">{{ place['name-de'] }}</h2>
            </header>
            <div class="place__categories">
                <ul class="place__categories__list">
                    <li class="place__categories__item" v-for="category in categories" :key="category" @click="jump(category['id'])">
                        {{ category['label-' + this.$i18n.locale] }}
                    </li>
                </ul>
            </div>
            <div class="place__video" v-if="place['video'] === true">
                <video width="1280" height="720" controls>
                    <source :src="getVideoUrl()" type="video/mp4" />
                </video>
            </div>
            <div class="place__images">
                <div class="place__image" v-for="image in images" :key="image" :id="getImgID( image.name )">
                    <div class="place__image__box">
                        <img :src="getImgUrl( image.name )" />
                    </div>
                    <div class="place__image__label">
                        {{ image['label-' + this.$i18n.locale] }}
                    </div>
                </div>
            </div>
            <div class="place__sources">
                <h3 class="place__sources__title">{{ $t('sources.title') }}</h3>
                <ul class="place__sources__list">
                    <li class="place__sources__item" v-for="source in sources" :key="source">
                        {{ source['label'][this.$i18n.locale] }}
                    </li>
                </ul>
            </div>
            <div class="place__jump" @click="jumpTop()">
                <span>&lt;</span>
            </div>
        </div>
    </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

export default {
    name: 'Place',
    props: ['placeId'],
    data() {
        return {
        };
    },
    computed: {
        sources() {
            return this.$store.state.sources;
        },
        categories() {
            const categories = this.$store.state.categories.filter( ( category ) => {
                let categoriesList = this.place.categories.split('|');
                return categoriesList.indexOf(category.id) != -1;
            } );

            return categories;
        },
        place() {
            const place = this.$store.state.places.find( ( item ) => {
                return item.id == this.placeId;
            } );

            return place;
        },
        images() {
            const images = this.$store.state.images.filter( ( item ) => {
                return item.place_id == this.placeId;
            } );

            return images;
        }
    },
    mounted() {
        const iconUrlVar = require('../assets/plyr.svg' );
        const player = new Plyr('video', {
            controls: ['play-large', 'play', 'progress', 'current-time'],
            loadSprite: false,
            iconUrl: iconUrlVar,
            fullscreen: {
                enabled: false
            }
        });

        const jumpTop = document.querySelector( '.place__jump' );
        let lastKnownScrollPosition = 0;
        let ticking = false;

        document.addEventListener('scroll', function(e) {
            lastKnownScrollPosition = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function() {
                    if ( lastKnownScrollPosition > 1080 ) {
                        jumpTop.classList.add( 'visible' );
                    } else {
                        jumpTop.classList.remove( 'visible' );
                    }

                    ticking = false;
                });

                ticking = true;
            }
        });
    },
    unmounted() {},
    methods: {
        jump( id ) {
            let firstElement = document.getElementById( id );
            let top = firstElement.offsetTop - 10;

            window.scrollTo( 0, top );
        },
        jumpTop() {
            window.scrollTo( 0, 0 );
        },
        getImgUrl( name ) {
            if (process.env.NODE_ENV === 'production' && process.env.IS_ELECTRON) {
                return `file://${__dirname}/images/${name}.jpg`
            } else {
                return `images/${name}.jpg`;
            }
        },
        getVideoUrl() {
            if (process.env.NODE_ENV === 'production' && process.env.IS_ELECTRON) {
                return `file://${__dirname}/videos/${this.placeId}.mp4`
            } else {
                return `videos/${this.placeId}.mp4`;
            }
        },
        getImgID( name ) {
            let replacedName = name.replace( /\d*_(\d*)(_\d*)?/, '$1' );
            replacedName = replacedName.replace( /^0+/, '' );
            return replacedName;
        }
    },
};
</script>

<style lang="scss">
.place {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: $base-gap * 5 $base-gap * 12 $base-gap * 16;
    display: flex;
    justify-content: flex-end;
    min-height: 100vh;
    position: relative;
    width: 100%;

    &__container {
        max-width: 1000px;
        width: 100%;
    }

    &__sources {
        position: fixed;
        bottom: $base-gap * 16;
        left: $base-gap * 5;

        &__title {
            @include base-title;
            color: $color-red-400;
            margin-bottom: $base-gap;
            font-weight: 500;
        }

        &__item {
            font-size: 14px;
            font-weight: 500;
        }

        &__item + &__item {
            margin-top: $base-gap * 0.5;
        }
    }

    &__header {
        margin-bottom: $base-gap * 3;
    }

    &__title {
        @include title;
        text-transform: uppercase;
        color: $color-red-400;
        margin-left: -3px;
    }

    &__subtitle {
        @include micro-title;
        text-transform: uppercase;
        color: $color-grey-400;
    }

    &__categories {
        margin-bottom: $base-gap * 2;
        border-bottom: 1px solid $color-red-400;
        padding-bottom: $base-gap * 2;

        &__list {
            display: flex;
        }

        &__item {
            @include base-title;
            color: $color-red-400;
            flex-shrink: 1;
            cursor: pointer;
        }

        &__item + &__item {
            margin-left: $base-gap * 3;
        }
    }

    &__video {
        margin-bottom: $base-gap * 5;
        background-color: $color-grey-400;

        video {
            max-width: 100%;
            height: auto;
            width: auto;
            display: block;
            max-height: 900px;
            width: 1000px;
            height: 562.5px;
        }
    }

    &__image {
        &__box {
            width: 100%;

            img {
                max-width: 100%;
                height: auto;
                width: auto;
                display: block;
                max-height: 900px;
            }
        }

        &__label {
            font-size: 16px;
            font-weight: 500;
            margin-top: $base-gap;
            color: #3c3c3c;
            line-height: 1.4em;
        }
    }

    &__image + &__image {
        margin-top: $base-gap * 4;
    }

    &__jump {
        @include base-title;
        color: $color-red-400;
        font-weight: 500;
        position: fixed;
        top: $base-gap * 4.5;
        right: $base-gap * 5.5;
        z-index: 999;
        font-size: 72px;
        transform: rotate(90deg);
        cursor: pointer;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 500ms;

        &.visible {
            opacity: 1;
        }
    }
}
</style>
