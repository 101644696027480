import { h } from 'vue'
import { createRouter, createWebHistory, createWebHashHistory, RouterView } from 'vue-router'
import Map from '../views/Map.vue'
import Topics from '../views/Topics.vue'
import Place from '../views/Place.vue'
import Topic from '../views/Topic.vue'
import TopicPlace from '../views/TopicPlace.vue'

const routes = [
	{
		path: '/',
		name: 'map',
		component: Map,
		children: [
			{
				path: 'place/:placeId/',
				name: 'place',
				props: true,
				component: Place
			}
		],
	},
	{
		path: '/topics',
		name: 'topics',
		component: { render: () => h( RouterView ) },
		children: [
			{
				path: '/topics',
				component: Topics
			},
			{
				path: ':topicId',
				name: 'topic',
				props: true,
				component: Topic
			},
			{
				path: ':topicId/place/:placeId',
				name: 'topic-place',
				props: true,
				component: TopicPlace
			}
		],
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return { top: 0 }
	}
})

export default router
