<template>
    <div class="map" v-bind:class="{ 'place-opened': opened }">
        <div class="map__content">
            <Mapbox />
        </div>
        <Content v-bind:title="title" v-bind:text="text" cta="Zobrazit mapu" opened="true" />
    </div>
    <div class="map-place" v-bind:class="{ opened: opened }">
        <span class="map-place__close" @click="close">&lt; {{ $t( 'navigation.back' ) }}</span>
        <router-view></router-view>
    </div>
</template>

<script>
import Mapbox from '@/components/map/Mapbox.vue';
import Content from '../views/Content.vue';

export default {
    name: 'Map',
    computed: {
        opened: function () {
            return this.$route.matched.some( ( { name } ) => {
                return name === 'place';
            } );
        },
        title: function() {
            return this.$store.state.copy.map.title;
        },
        text: function() {
            return this.$store.state.copy.map.text;
        }
    },
    methods: {
        close() {
            this.opened = false;
            this.$router.push({ name: 'map' });
        }
    },
    mounted() {
       
    },
    components: {
        Mapbox,
        Content
    },
};
</script>

<style lang="scss">
.map {
    position: relative;
    height: 100vh;

    &.place-opened {
        opacity: 0;
    }

    &__content {
        height: 100%;
    }
}

.map-place {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background-color: $color-red-100;
    display: none;
    z-index: 9;

    &.opened {
        display: block;
    }

    &__close {
        @include mini-title;
        position: fixed;
        top: $base-gap * 6;
        left: $base-gap * 5;
        cursor: pointer;
        color: $color-red-400;
        z-index: 99;
    }
}
</style>
