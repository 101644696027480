<template>
    <div class="offline-map">
        <div id="map"></div>
    </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import StyleJSON from './data/style.js';

export default {
    name: 'Mapbox',
    data() {
        return {
            isLoaded: false,
        };
    },
    computed: {},
    mounted() {
        const styleJSON = new StyleJSON();
        const places = this.$store.state.places;

        this.map = new mapboxgl.Map({
            container: 'map',
            style: styleJSON,
            center: [13.373637, 49.73843],
            zoom: 9.5,
            maxZoom: 12,
            maxBounds: [
                [12.3218, 48.8558],
                [13.8829, 50.1626],
            ],
        });

        // disable map rotation using right click + drag
        this.map.dragRotate.disable();

        // disable map rotation using touch rotation gesture
        this.map.touchZoomRotate.disableRotation();

        this.map.on('load', () => {
            this.isLoaded = true;
        });

        this.map.on('movestart', () => {
            this.$store.commit('closeWindow');
        });

        this.map.on('render', () => {
            document.documentElement.style.setProperty(
                '--zoom',
                this.map.getZoom() - 7
            );
            console.log(this.map.getZoom());
        });

        places.forEach((place) => {
            const markerEl = document.createElement('div');
            markerEl.className = 'marker';
            markerEl.innerHTML = '';

            const location = place.location;

            if (location.length > 0) {
                const coordinates = location.split(', ');
                new mapboxgl.Marker({ element: markerEl, offset: [0, -15] })
                    .setLngLat([coordinates[1], coordinates[0]])
                    .addTo(this.map);

                if ( place.detail === true ) {
                    markerEl.classList.add('marker--active');

                    markerEl.addEventListener('click', () => {
                        this.$store.commit('openWindow');
                        this.$store.commit('changeActive', place);

                        this.$router.push({ name: 'place', params: { placeId: place.id } })
                    });
                }
            }
        });
    },
    unmounted() {
        this.map.remove();
    },
    methods: {
        resizeHandler() {
            if (this.map) {
                this.map.resize();
            }
        },
    },
};
</script>

<style lang="scss">
.offline-map,
#map {
    height: 100%;
    width: 100%;
}

.marker {
    width: calc(3px * (var(--zoom)));
    height: calc(3px * (var(--zoom)));
    background-color: transparent;
    border: 1.5px solid $color-red-400;
    border-radius: calc(4px * var(--zoom));

    &--active {
        width: calc(6px * (var(--zoom)));
        height: calc(6px * (var(--zoom)));
        background-color: $color-red-400;
    }
}
</style>
