export default class StyleJSON {
  constructor() {
    const json = {
      "version": 8,
      "name": "Basic",
      "metadata": {
        "mapbox:autocomposite": false,
        "mapbox:groups": {
          "101da9f13b64a08fa4b6ac1168e89e5f": {
            "collapsed": false,
            "name": "Places"
          },
          "a14c9607bc7954ba1df7205bf660433f": {"name": "Boundaries"},
          "b6371a3f2f5a9932464fa3867530a2e5": {
            "collapsed": false,
            "name": "Transportation"
          }
        },
        "mapbox:type": "template",
        "openmaptiles:mapbox:owner": "openmaptiles",
        "openmaptiles:mapbox:source:url": "mapbox://openmaptiles.4qljc88t",
        "openmaptiles:version": "3.x",
        "maputnik:renderer": "mbgljs"
      },
      "sources": {
        "fix": {
          type: 'geojson',
          data: {
            "type": "FeatureCollection",
            "features": [
            {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "LineString",
                "coordinates": [
                [
                    13.765568733215332,
                    49.51398071175805
                ],
                [
                    13.762521743774414,
                    49.51409216492288
                ],
                [
                    13.759732246398926,
                    49.51342344212448
                ],
                [
                    13.756728172302246,
                    49.51230888380967
                ],
                [
                    13.75887393951416,
                    49.511027110350554
                ],
                [
                    13.76106262207031,
                    49.50985676611933
                ],
                [
                    13.758058547973631,
                    49.50974530330363
                ],
                [
                    13.75762939453125,
                    49.50849132912656
                ],
                [
                    13.754968643188477,
                    49.508853591634946
                ],
                [
                    13.753252029418944,
                    49.50835199667825
                ],
                [
                    13.753724098205566,
                    49.506763578719124
                ],
                [
                    13.75033378601074,
                    49.5066521088556
                ],
                [
                    13.750419616699219,
                    49.504812819439046
                ],
                [
                    13.752694129943848,
                    49.50419970760107
                ],
                [
                    13.754582405090332,
                    49.50297346087621
                ],
                [
                    13.756814002990723,
                    49.50280624303267
                ],
                [
                    13.758187294006348,
                    49.502388195923594
                ],
                [
                    13.758487701416016,
                    49.498987946776616
                ],
                [
                    13.758273124694824,
                    49.49720411503556
                ],
                [
                    13.756599426269531,
                    49.49706475044243
                ],
                [
                    13.754067420959473,
                    49.49812391139678
                ],
                [
                    13.75338077545166,
                    49.49734347923181
                ],
                [
                    13.752264976501463,
                    49.49770582428472
                ],
                [
                    13.7528657913208,
                    49.49533659651652
                ],
                [
                    13.751921653747559,
                    49.49444462222773
                ],
                [
                    13.74763011932373,
                    49.495392344369826
                ],
                [
                    13.747458457946777,
                    49.49201948495438
                ],
                [
                    13.749217987060545,
                    49.492493371798155
                ],
                [
                    13.750720024108887,
                    49.491712849846046
                ],
                [
                    13.748703002929688,
                    49.49040266001821
                ],
                [
                    13.750033378601074,
                    49.488590637577424
                ],
                [
                    13.748188018798828,
                    49.48666703267216
                ],
                [
                    13.751449584960938,
                    49.48563550325804
                ],
                [
                    13.752436637878418,
                    49.48510579051855
                ],
                [
                    13.753552436828613,
                    49.484046347846366
                ],
                [
                    13.755440711975098,
                    49.483014763217085
                ],
                [
                    13.756814002990723,
                    49.48290323925265
                ],
                [
                    13.757457733154297,
                    49.48309840602372
                ],
                [
                    13.75861644744873,
                    49.48254078461398
                ],
                [
                    13.75861644744873,
                    49.48164857714979
                ],
                [
                    13.759732246398926,
                    49.48201103839277
                ],
                [
                    13.759989738464354,
                    49.48295900126662
                ],
                [
                    13.759174346923828,
                    49.483293572016876
                ],
                [
                    13.758831024169922,
                    49.48410210855855
                ],
                [
                    13.76110553741455,
                    49.48354449857929
                ],
                [
                    13.767499923706055,
                    49.48373966279439
                ],
                [
                    13.767328262329102,
                    49.483014763217085
                ],
                [
                    13.769087791442871,
                    49.4827917150342
                ],
                [
                    13.768701553344725,
                    49.48228985290782
                ],
                [
                    13.768572807312012,
                    49.48120246732136
                ],
                [
                    13.766770362854002,
                    49.480895764456754
                ],
                [
                    13.764410018920898,
                    49.481174585322144
                ],
                [
                    13.762435913085938,
                    49.48170434059257
                ],
                [
                    13.76110553741455,
                    49.4818716305399
                ],
                [
                    13.759732246398926,
                    49.48153705007371
                ],
                [
                    13.759474754333496,
                    49.481007292993425
                ],
                [
                    13.761277198791504,
                    49.48044964776995
                ],
                [
                    13.76011848449707,
                    49.477494022065635
                ],
                [
                    13.760933876037596,
                    49.47699210564336
                ],
                [
                    13.76213550567627,
                    49.480505412578054
                ],
                [
                    13.763337135314941,
                    49.48033811796323
                ],
                [
                    13.763208389282227,
                    49.47930645520898
                ],
                [
                    13.765697479248047,
                    49.47738248552745
                ],
                [
                    13.760848045349121,
                    49.475514210751086
                ],
                [
                    13.760976791381834,
                    49.475123815975046
                ],
                [
                    13.766341209411621,
                    49.476294990967865
                ],
                [
                    13.766427040100098,
                    49.47554209597317
                ],
                [
                    13.768744468688963,
                    49.474538217976395
                ],
                [
                    13.766212463378906,
                    49.474315131182884
                ],
                [
                    13.766083717346191,
                    49.47300447575049
                ],
                [
                    13.763937950134276,
                    49.47208420736219
                ],
                [
                    13.763422966003418,
                    49.470857155949346
                ],
                [
                    13.765010833740234,
                    49.46990895880499
                ],
                [
                    13.764281272888184,
                    49.46965796237109
                ],
                [
                    13.766684532165527,
                    49.4680125094553
                ],
                [
                    13.765912055969237,
                    49.46739893659452
                ],
                [
                    13.765912055969237,
                    49.46664590485363
                ],
                [
                    13.766555786132812,
                    49.465892861538286
                ],
                [
                    13.765954971313477,
                    49.46511191550435
                ],
                [
                    13.764495849609375,
                    49.46491667705091
                ],
                [
                    13.763251304626465,
                    49.46491667705091
                ],
                [
                    13.762049674987793,
                    49.463996256719895
                ],
                [
                    13.762178421020508,
                    49.46098385110948
                ],
                [
                    13.762950897216795,
                    49.46011914492049
                ],
                [
                    13.768229484558105,
                    49.45911495084281
                ],
                [
                    13.768229484558105,
                    49.458278106725714
                ],
                [
                    13.765053749084473,
                    49.45844547669233
                ],
                [
                    13.764667510986328,
                    49.45732966611792
                ],
                [
                    13.765568733215332,
                    49.45649279151525
                ],
                [
                    13.766427040100098,
                    49.45534903978114
                ],
                [
                    13.765439987182617,
                    49.45403787690848
                ],
                [
                    13.76462459564209,
                    49.454009979445026
                ],
                [
                    13.764152526855469,
                    49.452726678960815
                ],
                [
                    13.766984939575195,
                    49.4501041778394
                ],
                [
                    13.769645690917969,
                    49.448709173271645
                ],
                [
                    13.769731521606444,
                    49.44714672102774
                ],
                [
                    13.771190643310547,
                    49.44854177005588
                ],
                [
                    13.772735595703123,
                    49.445026170477
                ],
                [
                    13.77161979675293,
                    49.44513778068728
                ],
                [
                    13.771448135375977,
                    49.444802949294235
                ],
                [
                    13.773164749145508,
                    49.444468115614576
                ],
                [
                    13.774881362915039,
                    49.44346360085607
                ],
                [
                    13.775138854980469,
                    49.44240325739602
                ],
                [
                    13.777713775634766,
                    49.440617363949144
                ],
                [
                    13.777885437011719,
                    49.44190098143812
                ],
                [
                    13.77934455871582,
                    49.44178936385991
                ],
                [
                    13.77985954284668,
                    49.43927790118021
                ],
                [
                    13.776683807373047,
                    49.439724392835
                ],
                [
                    13.77685546875,
                    49.43832909291618
                ],
                [
                    13.778657913208008,
                    49.43710119614862
                ],
                [
                    13.782777786254883,
                    49.435817453020604
                ],
                [
                    13.788957595825195,
                    49.43531510960637
                ],
                [
                    13.783721923828125,
                    49.43431040734267
                ],
                [
                    13.781147003173828,
                    49.434254589946796
                ],
                [
                    13.777027130126953,
                    49.43241258024849
                ],
                [
                    13.775739669799805,
                    49.43129617706857
                ],
                [
                    13.775739669799805,
                    49.430458858009445
                ],
                [
                    13.775053024291992,
                    49.42984481494923
                ],
                [
                    13.775138854980469,
                    49.428449233954964
                ],
                [
                    13.77161979675293,
                    49.42822593731166
                ],
                [
                    13.772306442260742,
                    49.426830310264066
                ],
                [
                    13.769731521606444,
                    49.42632787480804
                ],
                [
                    13.76810073852539,
                    49.42621622178571
                ],
                [
                    13.767070770263672,
                    49.42420642393453
                ],
                [
                    13.768444061279297,
                    49.42281068254586
                ],
                [
                    13.763294219970703,
                    49.42214071257704
                ],
                [
                    13.763294219970703,
                    49.42091241054287
                ],
                [
                    13.767242431640625,
                    49.42091241054287
                ],
                [
                    13.762435913085938,
                    49.41789735701559
                ],
                [
                    13.763809204101562,
                    49.41689229800882
                ],
                [
                    13.763980865478514,
                    49.414435400453954
                ],
                [
                    13.762264251708984,
                    49.413430270551906
                ],
                [
                    13.761749267578125,
                    49.41119657485587
                ],
                [
                    13.765182495117188,
                    49.411084887402914
                ],
                [
                    13.76363754272461,
                    49.40918616182351
                ],
                [
                    13.764152526855469,
                    49.405835290473824
                ],
                [
                    13.76260757446289,
                    49.405165088759226
                ],
                [
                    13.764324188232422,
                    49.40315442872555
                ],
                [
                    13.760547637939453,
                    49.40449487789633
                ],
                [
                    13.759517669677734,
                    49.40427147224242
                ],
                [
                    13.759002685546873,
                    49.403712953660495
                ],
                [
                    13.759689331054688,
                    49.40203735979645
                ],
                [
                    13.76312255859375,
                    49.40192565150588
                ],
                [
                    13.759174346923828,
                    49.39991485881999
                ],
                [
                    13.759002685546873,
                    49.398462568451514
                ],
                [
                    13.760719299316406,
                    49.39868600053436
                ],
                [
                    13.761920928955076,
                    49.397568829954885
                ],
                [
                    13.763294219970703,
                    49.39712195460748
                ],
                [
                    13.762092590332031,
                    49.396786795428525
                ],
                [
                    13.763465881347656,
                    49.39578130416867
                ],
                [
                    13.763465881347656,
                    49.394552342452045
                ],
                [
                    13.765182495117188,
                    49.393658532798526
                ],
                [
                    13.765525817871094,
                    49.39209432676797
                ],
                [
                    13.764324188232422,
                    49.39164740161032
                ],
                [
                    13.764495849609375,
                    49.39108873944513
                ],
                [
                    13.765182495117188,
                    49.39086527280011
                ],
                [
                    13.763809204101562,
                    49.39019486676566
                ],
                [
                    13.76312255859375,
                    49.38918924055953
                ],
                [
                    13.764495849609375,
                    49.387401409809186
                ],
                [
                    13.766212463378906,
                    49.387513151137156
                ],
                [
                    13.766555786132812,
                    49.38639572642098
                ],
                [
                    13.768444061279297,
                    49.38661921339738
                ],
                [
                    13.768959045410156,
                    49.38471954169448
                ],
                [
                    13.77187728881836,
                    49.384496046077125
                ],
                [
                    13.775997161865234,
                    49.38181401937437
                ],
                [
                    13.773250579833984,
                    49.38136700069147
                ],
                [
                    13.772220611572266,
                    49.380584708211565
                ],
                [
                    13.775482177734375,
                    49.378908325247885
                ],
                [
                    13.77410888671875,
                    49.37801423095167
                ],
                [
                    13.7713623046875,
                    49.37801423095167
                ],
                [
                    13.7713623046875,
                    49.3762259935611
                ],
                [
                    13.773937225341797,
                    49.37555538776515
                ],
                [
                    13.773937225341797,
                    49.377008355425446
                ],
                [
                    13.77685546875,
                    49.37678482473499
                ],
                [
                    13.778228759765625,
                    49.3756671560332
                ],
                [
                    13.781661987304688,
                    49.373990605325545
                ],
                [
                    13.784236907958984,
                    49.37432592004196
                ],
                [
                    13.785781860351562,
                    49.37287287308352
                ],
                [
                    13.788185119628906,
                    49.37186689233472
                ],
                [
                    13.788185119628906,
                    49.37097266994149
                ],
                [
                    13.790416717529297,
                    49.37097266994149
                ],
                [
                    13.791618347167969,
                    49.36840168995037
                ],
                [
                    13.789730072021484,
                    49.36784276346482
                ],
                [
                    13.789386749267578,
                    49.365048035722864
                ],
                [
                    13.790416717529297,
                    49.36448907111148
                ],
                [
                    13.79058837890625,
                    49.36270034165353
                ],
                [
                    13.788185119628906,
                    49.36314753011825
                ],
                [
                    13.785953521728516,
                    49.36270034165353
                ],
                [
                    13.786640167236328,
                    49.36147055240588
                ],
                [
                    13.783378601074217,
                    49.35968171314292
                ],
                [
                    13.781490325927733,
                    49.359234493159946
                ],
                [
                    13.781147003173828,
                    49.357892808809474
                ],
                [
                    13.779430389404295,
                    49.356551087856495
                ],
                [
                    13.778915405273438,
                    49.35487388519263
                ],
                [
                    13.782176971435547,
                    49.35498570048287
                ],
                [
                    13.783206939697266,
                    49.3563274641391
                ],
                [
                    13.783893585205078,
                    49.35800461723669
                ],
                [
                    13.786125183105469,
                    49.35878726911004
                ],
                [
                    13.785266876220701,
                    49.35465025384961
                ],
                [
                    13.783893585205078,
                    49.353643900223595
                ],
                [
                    13.786125183105469,
                    49.351407485117285
                ],
                [
                    13.783721923828125,
                    49.351854776272674
                ],
                [
                    13.780803680419922,
                    49.35051289060523
                ],
                [
                    13.776168823242188,
                    49.350401065147345
                ],
                [
                    13.775482177734375,
                    49.34760534608687
                ],
                [
                    13.772392272949219,
                    49.34592783839135
                ],
                [
                    13.76981735229492,
                    49.343355548839206
                ],
                [
                    13.767757415771483,
                    49.33921723565357
                ],
                [
                    13.768444061279297,
                    49.336980164708436
                ],
                [
                    13.772735595703123,
                    49.336980164708436
                ],
                [
                    13.772735595703123,
                    49.33474299207837
                ],
                [
                    13.766555786132812,
                    49.33474299207837
                ],
                [
                    13.765869140624998,
                    49.33272944976965
                ],
                [
                    13.769645690917969,
                    49.33116330436252
                ],
                [
                    13.771705627441404,
                    49.328702118051346
                ],
                [
                    13.773422241210938,
                    49.328478367739876
                ],
                [
                    13.770675659179686,
                    49.32624080869595
                ],
                [
                    13.770675659179686,
                    49.32310805519368
                ],
                [
                    13.756599426269531,
                    49.32512199104001
                ],
                [
                    13.755226135253904,
                    49.32310805519368
                ],
                [
                    13.750419616699219,
                    49.32288427945948
                ],
                [
                    13.750762939453125,
                    49.32064646618636
                ],
                [
                    13.749046325683594,
                    49.32019889132852
                ],
                [
                    13.747673034667969,
                    49.31863234729242
                ],
                [
                    13.744411468505858,
                    49.31840855121949
                ],
                [
                    13.742523193359373,
                    49.31762525695567
                ],
                [
                    13.74114990234375,
                    49.31728955560049
                ],
                [
                    13.7384033203125,
                    49.31717765464028
                ],
                [
                    13.73617172241211,
                    49.31505148809065
                ],
                [
                    13.734970092773438,
                    49.311470368545095
                ],
                [
                    13.73462677001953,
                    49.307553220937564
                ],
                [
                    13.728790283203123,
                    49.309120117355285
                ],
                [
                    13.723640441894531,
                    49.31068696393997
                ],
                [
                    13.722267150878906,
                    49.31001546436281
                ],
                [
                    13.723468780517578,
                    49.30889627806091
                ],
                [
                    13.721923828124998,
                    49.307105527093746
                ],
                [
                    13.726387023925781,
                    49.304643138236166
                ],
                [
                    13.725528717041016,
                    49.30329996517298
                ],
                [
                    13.727245330810547,
                    49.30240449612361
                ],
                [
                    13.724498748779297,
                    49.29949410933021
                ],
                [
                    13.720550537109375,
                    49.299046342260134
                ],
                [
                    13.724498748779297,
                    49.29602380812735
                ],
                [
                    13.7274169921875,
                    49.29613575714144
                ],
                [
                    13.726558685302734,
                    49.29501625555856
                ],
                [
                    13.72415542602539,
                    49.29512820686107
                ],
                [
                    13.725013732910154,
                    49.292441305422656
                ],
                [
                    13.730335235595703,
                    49.293113044512886
                ],
                [
                    13.73342514038086,
                    49.291881515854975
                ],
                [
                    13.735485076904297,
                    49.29199347427707
                ],
                [
                    13.734970092773438,
                    49.290426033229004
                ],
                [
                    13.73617172241211,
                    49.28852264495486
                ],
                [
                    13.733596801757812,
                    49.287067063161125
                ],
                [
                    13.73342514038086,
                    49.28605932751487
                ],
                [
                    13.732051849365234,
                    49.28493959708459
                ],
                [
                    13.730506896972656,
                    49.28437972233387
                ],
                [
                    13.73085021972656,
                    49.28214015975995
                ],
                [
                    13.731365203857422,
                    49.279452550408074
                ],
                [
                    13.729476928710938,
                    49.27928456996032
                ],
                [
                    13.7274169921875,
                    49.278388664575886
                ],
                [
                    13.724069595336914,
                    49.27704477598428
                ],
                [
                    13.718748092651367,
                    49.27766072946757
                ],
                [
                    13.718748092651367,
                    49.27861264244774
                ],
                [
                    13.71591567993164,
                    49.27850065363896
                ],
                [
                    13.714370727539062,
                    49.2775487384973
                ],
                [
                    13.712310791015623,
                    49.2766528015807
                ],
                [
                    13.711366653442381,
                    49.27637281795668
                ],
                [
                    13.708019256591797,
                    49.27743674727277
                ],
                [
                    13.706216812133789,
                    49.278332669948995
                ],
                [
                    13.703470230102537,
                    49.27889261335716
                ],
                [
                    13.702096939086914,
                    49.27906059513996
                ],
                [
                    13.699178695678711,
                    49.279844502561154
                ],
                [
                    13.698577880859373,
                    49.278668636756784
                ],
                [
                    13.699522018432615,
                    49.27704477598428
                ],
                [
                    13.702611923217773,
                    49.2766528015807
                ],
                [
                    13.703384399414062,
                    49.27290088897578
                ],
                [
                    13.702440261840819,
                    49.270436791120694
                ],
                [
                    13.702611923217773,
                    49.26853263120442
                ],
                [
                    13.704328536987305,
                    49.26679642134555
                ],
                [
                    13.705873489379883,
                    49.26646037367071
                ],
                [
                    13.70492935180664,
                    49.265060150389495
                ],
                [
                    13.70518684387207,
                    49.26253964834898
                ],
                [
                    13.706560134887695,
                    49.26265167339575
                ],
                [
                    13.708019256591797,
                    49.2615314114841
                ],
                [
                    13.70767593383789,
                    49.25710612830833
                ],
                [
                    13.710079193115234,
                    49.25912276225693
                ],
                [
                    13.713855743408203,
                    49.257890384635154
                ],
                [
                    13.714284896850584,
                    49.25637787913232
                ],
                [
                    13.718404769897461,
                    49.256713995471806
                ],
                [
                    13.723039627075194,
                    49.25733020281615
                ],
                [
                    13.727073669433594,
                    49.2572181656894
                ],
                [
                    13.728618621826172,
                    49.25861861149468
                ],
                [
                    13.731365203857422,
                    49.25845056009618
                ],
                [
                    13.73316764831543,
                    49.256153800301036
                ],
                [
                    13.728361129760742,
                    49.25626583984384
                ],
                [
                    13.726644515991211,
                    49.254585219998155
                ],
                [
                    13.724584579467773,
                    49.25408102288605
                ],
                [
                    13.723640441894531,
                    49.252344304524165
                ],
                [
                    13.720293045043945,
                    49.25228828033389
                ],
                [
                    13.719778060913086,
                    49.25027136713893
                ],
                [
                    13.719005584716795,
                    49.24926287964059
                ],
                [
                    13.723125457763672,
                    49.24797422674688
                ],
                [
                    13.724584579467773,
                    49.24550888401142
                ],
                [
                    13.726558685302734,
                    49.24354772802199
                ],
                [
                    13.725013732910154,
                    49.24265117362729
                ],
                [
                    13.715314865112305,
                    49.24265117362729
                ],
                [
                    13.713769912719727,
                    49.24365979617681
                ],
                [
                    13.713769912719727,
                    49.24584507436022
                ],
                [
                    13.70793342590332,
                    49.24629332459796
                ],
                [
                    13.705615997314453,
                    49.24522872363889
                ],
                [
                    13.704586029052734,
                    49.2434916938492
                ],
                [
                    13.704671859741211,
                    49.241922711195905
                ],
                [
                    13.707160949707031,
                    49.24225892596105
                ],
                [
                    13.70939254760742,
                    49.241586494141664
                ],
                [
                    13.707504272460938,
                    49.240129527121994
                ],
                [
                    13.709564208984375,
                    49.2386725171185
                ],
                [
                    13.709735870361328,
                    49.236318794773474
                ],
                [
                    13.705272674560547,
                    49.23732754665601
                ],
                [
                    13.70269775390625,
                    49.23833627793654
                ],
                [
                    13.699951171875,
                    49.238112117210164
                ],
                [
                    13.69720458984375,
                    49.236991298317605
                ],
                [
                    13.692741394042967,
                    49.2337407796959
                ],
                [
                    13.691024780273438,
                    49.232059393025075
                ],
                [
                    13.692398071289062,
                    49.23161101358084
                ],
                [
                    13.694801330566404,
                    49.23261986160747
                ],
                [
                    13.695316314697266,
                    49.22914485389208
                ],
                [
                    13.692913055419922,
                    49.23004165347732
                ],
                [
                    13.689823150634764,
                    49.22914485389208
                ],
                [
                    13.68896484375,
                    49.227351205885064
                ],
                [
                    13.68896484375,
                    49.22510905430699
                ],
                [
                    13.692741394042967,
                    49.225333274043294
                ],
                [
                    13.691539764404297,
                    49.22398794036374
                ],
                [
                    13.691539764404297,
                    49.22219410514691
                ],
                [
                    13.692913055419922,
                    49.22028808387943
                ],
                [
                    13.695831298828125,
                    49.221409281763144
                ],
                [
                    13.697032928466797,
                    49.22084868600088
                ],
                [
                    13.696346282958984,
                    49.21714859448064
                ],
                [
                    13.698921203613281,
                    49.21613943053532
                ],
                [
                    13.69720458984375,
                    49.214793846557136
                ],
                [
                    13.700294494628906,
                    49.2127754019084
                ],
                [
                    13.70819091796875,
                    49.21109330174257
                ],
                [
                    13.70939254760742,
                    49.211654008157325
                ],
                [
                    13.712139129638672,
                    49.210532588968306
                ],
                [
                    13.710594177246094,
                    49.20918685236314
                ],
                [
                    13.71591567993164,
                    49.20851397032389
                ],
                [
                    13.714885711669922,
                    49.2073924799078
                ],
                [
                    13.715057373046875,
                    49.20526157803394
                ],
                [
                    13.713340759277344,
                    49.20357922221202
                ],
                [
                    13.714027404785156,
                    49.20021433885426
                ],
                [
                    13.715400695800781,
                    49.20021433885426
                ],
                [
                    13.7164306640625,
                    49.198756151629496
                ],
                [
                    13.715229034423826,
                    49.19830746998781
                ],
                [
                    13.719005584716795,
                    49.19583964820061
                ],
                [
                    13.719863891601562,
                    49.19460569113345
                ],
                [
                    13.717975616455078,
                    49.19426915204543
                ],
                [
                    13.717975616455078,
                    49.19348388526928
                ],
                [
                    13.717460632324219,
                    49.19292297279707
                ],
                [
                    13.71694564819336,
                    49.19236205396477
                ],
                [
                    13.713855743408203,
                    49.191801128772326
                ],
                [
                    13.711109161376953,
                    49.19146457060401
                ],
                [
                    13.709907531738281,
                    49.19247423824004
                ]
                ]
            }
            },
            {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "LineString",
                "coordinates": [
                [
                    13.7099289894104,
                    49.19247423824004
                ],
                [
                    13.708834648132324,
                    49.191997453313135
                ],
                [
                    13.706431388854979,
                    49.19292297279707
                ],
                [
                    13.704371452331543,
                    49.19334365774749
                ],
                [
                    13.703727722167969,
                    49.19324549824572
                ],
                [
                    13.701024055480957,
                    49.19283883537769
                ],
                [
                    13.698406219482422,
                    49.19196940699789
                ],
                [
                    13.69664669036865,
                    49.19095972905842
                ],
                [
                    13.695101737976074,
                    49.18941712574896
                ],
                [
                    13.696303367614746,
                    49.188183008470006
                ],
                [
                    13.694243431091309,
                    49.18815495999226
                ],
                [
                    13.691582679748535,
                    49.18703300784411
                ],
                [
                    13.691368103027344,
                    49.18532198183587
                ],
                [
                    13.690552711486816,
                    49.1827693576562
                ],
                [
                    13.69171142578125,
                    49.18114234166837
                ],
                [
                    13.693084716796875,
                    49.17149135425113
                ],
                [
                    13.694114685058594,
                    49.16947114199514
                ],
                [
                    13.692398071289062,
                    49.16902219474314
                ],
                [
                    13.690338134765625,
                    49.168124288026284
                ],
                [
                    13.685874938964842,
                    49.16745084730228
                ],
                [
                    13.685016632080076,
                    49.16565496059055
                ],
                [
                    13.688278198242188,
                    49.16397125764106
                ],
                [
                    13.688278198242188,
                    49.16509373263508
                ],
                [
                    13.692741394042967,
                    49.1648692396718
                ],
                [
                    13.693428039550781,
                    49.165542715508344
                ],
                [
                    13.695144653320312,
                    49.16509373263508
                ],
                [
                    13.692569732666016,
                    49.16251200210978
                ],
                [
                    13.690509796142578,
                    49.162175244726704
                ],
                [
                    13.689994812011719,
                    49.15936884414503
                ],
                [
                    13.688278198242188,
                    49.16206299175678
                ],
                [
                    13.68690490722656,
                    49.16329776043147
                ],
                [
                    13.684501647949219,
                    49.162175244726704
                ],
                [
                    13.680896759033203,
                    49.16419575467542
                ],
                [
                    13.677635192871094,
                    49.16183848505361
                ],
                [
                    13.675231933593748,
                    49.16284875720288
                ],
                [
                    13.6724853515625,
                    49.16116495883738
                ],
                [
                    13.671112060546875,
                    49.15981787892485
                ],
                [
                    13.66544723510742,
                    49.15936884414503
                ],
                [
                    13.658409118652344,
                    49.16049142346117
                ],
                [
                    13.658065795898438,
                    49.158807544945184
                ],
                [
                    13.659954071044922,
                    49.155439616159875
                ],
                [
                    13.659954071044922,
                    49.15420465155963
                ],
                [
                    13.656864166259766,
                    49.15263283390248
                ],
                [
                    13.65652084350586,
                    49.150724131126566
                ],
                [
                    13.654460906982422,
                    49.15016273396198
                ],
                [
                    13.653774261474608,
                    49.14870307156213
                ],
                [
                    13.64776611328125,
                    49.14892763780735
                ],
                [
                    13.646392822265625,
                    49.14769251086286
                ],
                [
                    13.64175796508789,
                    49.14241480344641
                ],
                [
                    13.637123107910156,
                    49.14039340493346
                ],
                [
                    13.633861541748047,
                    49.138259617056015
                ],
                [
                    13.630084991455078,
                    49.13567648776643
                ],
                [
                    13.62802505493164,
                    49.13769807866191
                ],
                [
                    13.627681732177734,
                    49.139719587107166
                ],
                [
                    13.622360229492188,
                    49.1409549127907
                ],
                [
                    13.618583679199219,
                    49.14106721359874
                ],
                [
                    13.615837097167967,
                    49.14129181445139
                ],
                [
                    13.613262176513672,
                    49.14308858462884
                ],
                [
                    13.604850769042969,
                    49.14353776699441
                ],
                [
                    13.605365753173828,
                    49.14454841243056
                ],
                [
                    13.607940673828123,
                    49.14533445574185
                ],
                [
                    13.608283996582031,
                    49.14690650495797
                ],
                [
                    13.602275848388672,
                    49.14735565271612
                ],
                [
                    13.594379425048826,
                    49.14825393601819
                ],
                [
                    13.59060287475586,
                    49.147243366158264
                ],
                [
                    13.589057922363281,
                    49.14600819722735
                ],
                [
                    13.589744567871094,
                    49.1448852896622
                ],
                [
                    13.591976165771484,
                    49.144211532908734
                ],
                [
                    13.593521118164062,
                    49.14387465109667
                ],
                [
                    13.594551086425781,
                    49.14219020768315
                ],
                [
                    13.59626770019531,
                    49.14353776699441
                ],
                [
                    13.599185943603514,
                    49.143313176320554
                ],
                [
                    13.599529266357422,
                    49.14241480344641
                ],
                [
                    13.60055923461914,
                    49.14162871382183
                ],
                [
                    13.599700927734375,
                    49.14050570701386
                ],
                [
                    13.599700927734375,
                    49.13938267475864
                ],
                [
                    13.599014282226562,
                    49.13814730988615
                ],
                [
                    13.59609603881836,
                    49.136911914222146
                ],
                [
                    13.592662811279297,
                    49.132194665725024
                ],
                [
                    13.593349456787108,
                    49.13084679798805
                ],
                [
                    13.593349456787108,
                    49.129948199137985
                ],
                [
                    13.592319488525389,
                    49.12904958400081
                ],
                [
                    13.590774536132812,
                    49.12826328239519
                ],
                [
                    13.590087890625,
                    49.12713997275566
                ],
                [
                    13.590259552001953,
                    49.12590430275864
                ],
                [
                    13.59060287475586,
                    49.12511795127304
                ],
                [
                    13.590774536132812,
                    49.123657551144355
                ],
                [
                    13.591289520263672,
                    49.122646479706525
                ],
                [
                    13.593177795410156,
                    49.1218600765587
                ],
                [
                    13.594722747802734,
                    49.119725490868085
                ],
                [
                    13.594894409179688,
                    49.115456043863304
                ],
                [
                    13.595924377441406,
                    49.11354590911753
                ],
                [
                    13.59781265258789,
                    49.112085168212516
                ],
                [
                    13.597126007080078,
                    49.109163557366074
                ],
                [
                    13.59781265258789,
                    49.10848931504532
                ],
                [
                    13.597126007080078,
                    49.107253180326865
                ],
                [
                    13.59506607055664,
                    49.107140802916284
                ],
                [
                    13.58734130859375,
                    49.106578912045634
                ],
                [
                    13.585453033447266,
                    49.10556749244393
                ],
                [
                    13.583908081054688,
                    49.10635415391575
                ],
                [
                    13.580474853515625,
                    49.106129394767834
                ],
                [
                    13.579959869384766,
                    49.10343220558305
                ],
                [
                    13.582019805908203,
                    49.09781259058364
                ],
                [
                    13.58734130859375,
                    49.09365366582339
                ],
                [
                    13.593692779541016,
                    49.091742691671094
                ],
                [
                    13.596611022949219,
                    49.08870746388217
                ],
                [
                    13.598155975341797,
                    49.0880329436187
                ],
                [
                    13.599014282226562,
                    49.086346602869945
                ],
                [
                    13.599185943603514,
                    49.08533477093022
                ],
                [
                    13.59506607055664,
                    49.08308618169088
                ],
                [
                    13.594379425048826,
                    49.08083749063311
                ],
                [
                    13.596782684326172,
                    49.07892602317838
                ],
                [
                    13.598670959472656,
                    49.07836381286786
                ],
                [
                    13.60055923461914,
                    49.07487796690195
                ],
                [
                    13.603992462158203,
                    49.07229153600914
                ],
                [
                    13.606224060058594,
                    49.07094204034674
                ],
                [
                    13.606395721435547,
                    49.069817432626316
                ],
                [
                    13.605880737304688,
                    49.06610604653569
                ],
                [
                    13.606395721435547,
                    49.06441896121407
                ],
                [
                    13.605537414550781,
                    49.06351915895547
                ],
                [
                    13.605365753173828,
                    49.06194446579895
                ],
                [
                    13.60433578491211,
                    49.0598073024207
                ],
                [
                    13.604507446289062,
                    49.05856995530203
                ],
                [
                    13.603477478027344,
                    49.05542020545491
                ],
                [
                    13.602447509765625,
                    49.05407025156395
                ],
                [
                    13.5955810546875,
                    49.051482737469776
                ],
                [
                    13.594036102294922,
                    49.05002016993045
                ],
                [
                    13.590774536132812,
                    49.04754495761653
                ],
                [
                    13.58854293823242,
                    49.04574472581076
                ],
                [
                    13.586139678955078,
                    49.0447320667783
                ],
                [
                    13.584423065185547,
                    49.04383190810366
                ],
                [
                    13.582534790039062,
                    49.04495710390102
                ],
                [
                    13.580989837646484,
                    49.04698239217853
                ],
                [
                    13.580131530761719,
                    49.04810751668978
                ],
                [
                    13.578071594238281,
                    49.04833253853693
                ],
                [
                    13.576869964599608,
                    49.04822002774063
                ],
                [
                    13.575153350830078,
                    49.048895088699496
                ],
                [
                    13.574981689453125,
                    49.04979515572098
                ],
                [
                    13.572406768798828,
                    49.050920216584856
                ],
                [
                    13.571548461914062,
                    49.05058270099887
                ],
                [
                    13.569316864013672,
                    49.04799500538431
                ],
                [
                    13.565196990966797,
                    49.04506962208049
                ],
                [
                    13.563995361328125,
                    49.04338182265603
                ],
                [
                    13.563652038574219,
                    49.042369115505004
                ],
                [
                    13.561077117919922,
                    49.04056869631684
                ],
                [
                    13.560218811035154,
                    49.03966846228119
                ],
                [
                    13.558673858642578,
                    49.03910580773438
                ],
                [
                    13.55712890625,
                    49.0380930135102
                ],
                [
                    13.55661392211914,
                    49.036517514836994
                ],
                [
                    13.557472229003906,
                    49.03167817117755
                ],
                [
                    13.556098937988281,
                    49.031002876495144
                ],
                [
                    13.554725646972656,
                    49.030552674948
                ],
                [
                    13.553695678710938,
                    49.030327572646705
                ],
                [
                    13.552837371826172,
                    49.0284141619659
                ],
                [
                    13.553009033203125,
                    49.027176033491095
                ],
                [
                    13.553009033203125,
                    49.02661323763608
                ],
                [
                    13.548717498779297,
                    49.02436199056141
                ],
                [
                    13.549060821533203,
                    49.02312376125763
                ],
                [
                    13.546314239501953,
                    49.021322645453715
                ],
                [
                    13.544082641601562,
                    49.01918373577401
                ],
                [
                    13.543567657470703,
                    49.01794537763023
                ],
                [
                    13.546314239501953,
                    49.016706988676425
                ],
                [
                    13.545970916748047,
                    49.01456788064106
                ],
                [
                    13.544425964355469,
                    49.01209090386598
                ],
                [
                    13.543739318847654,
                    49.00961380384713
                ],
                [
                    13.545455932617188,
                    49.00803740330258
                ],
                [
                    13.546142578125,
                    49.00724918431423
                ],
                [
                    13.546142578125,
                    49.00488445248426
                ],
                [
                    13.54391098022461,
                    49.00285745010684
                ],
                [
                    13.543567657470703,
                    49.00139345263396
                ],
                [
                    13.542022705078125,
                    48.9997041713043
                ],
                [
                    13.54116439819336,
                    48.998803197833915
                ],
                [
                    13.537731170654297,
                    48.997451707070006
                ],
                [
                    13.539276123046875,
                    48.99474861553138
                ],
                [
                    13.539447784423828,
                    48.99238329010947
                ],
                [
                    13.538074493408203,
                    48.9904684206079
                ],
                [
                    13.53567123413086,
                    48.98630050904827
                ],
                [
                    13.536701202392578,
                    48.98483602492272
                ],
                [
                    13.543567657470703,
                    48.981343619861
                ],
                [
                    13.54863166809082,
                    48.97968182507257
                ],
                [
                    13.549790382385254,
                    48.97968182507257
                ],
                [
                    13.55064868927002,
                    48.97917482580138
                ],
                [
                    13.551592826843262,
                    48.97740028773914
                ],
                [
                    13.551335334777832,
                    48.97678059289811
                ],
                [
                    13.549232482910156,
                    48.97517498406261
                ],
                [
                    13.548717498779297,
                    48.974752446826194
                ],
                [
                    13.549189567565918,
                    48.97348481362809
                ],
                [
                    13.550090789794922,
                    48.97266787737623
                ],
                [
                    13.550348281860352,
                    48.97148470458835
                ],
                [
                    13.551421165466307,
                    48.971090307419274
                ],
                [
                    13.551592826843262,
                    48.97035784725648
                ],
                [
                    13.551464080810545,
                    48.96965354849066
                ],
                [
                    13.551169037818909,
                    48.969058408278364
                ],
                [
                    13.550949096679688,
                    48.968413958499255
                ],
                [
                    13.550949096679688,
                    48.967596939155555
                ]
                ]
            }
            }
            ]
          }
        },
        "openmaptiles": {
          "type": "vector",
          "basename": "tiles",
          "attribution": "",
          "center": [ 14.440011921609084, 50.095511167610454, 14 ],
          "format": "pbf",
          "description": "Extract from https://openmaptiles.org",
          "planettime": "1499040000000",
          "bounds": [ 12.3218, 48.8558, 13.8829, 50.1626 ],
          "pixel_scale": "256",
          "maskLevel": "5",
          "minzoom": 0,
          "version": "3.6.1",
          "mtime": "1499626373833",
          "maxzoom": 14,
          "id": "openmaptiles",
          "name": "OpenMapTiles",
          "profile": "mercator",
          "scale": 1,
          "tiles": [ location.origin + '/map/tiles/{z}/{x}/{y}.pbf' ],
          "tilejson": "2.0.0",
          "scheme": "xyz",
          "vector_layers": [ {
              "maxzoom": 14,
              "fields": {
                  "class": "String"
              },
              "minzoom": 0,
              "id": "water",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "name:mt": "String",
                  "name:pt": "String",
                  "name:az": "String",
                  "name:ka": "String",
                  "name:rm": "String",
                  "name:ko": "String",
                  "name:kn": "String",
                  "name:ar": "String",
                  "name:cs": "String",
                  "name_de": "String",
                  "name:ro": "String",
                  "name:it": "String",
                  "name_int": "String",
                  "name:ru": "String",
                  "name:pl": "String",
                  "name:ca": "String",
                  "name:lv": "String",
                  "name:bg": "String",
                  "name:cy": "String",
                  "name:fi": "String",
                  "name:he": "String",
                  "name:da": "String",
                  "name:de": "String",
                  "name:tr": "String",
                  "name:fr": "String",
                  "name:mk": "String",
                  "name:nonlatin": "String",
                  "name:fy": "String",
                  "name:be": "String",
                  "name:zh": "String",
                  "name:sr": "String",
                  "name:sl": "String",
                  "name:nl": "String",
                  "name:ja": "String",
                  "name:lt": "String",
                  "name:no": "String",
                  "name:kk": "String",
                  "name:ko_rm": "String",
                  "name:ja_rm": "String",
                  "name:br": "String",
                  "name:bs": "String",
                  "name:lb": "String",
                  "name:la": "String",
                  "name:sk": "String",
                  "name:uk": "String",
                  "name:hy": "String",
                  "name:sv": "String",
                  "name_en": "String",
                  "name:hu": "String",
                  "name:hr": "String",
                  "class": "String",
                  "name:sq": "String",
                  "name:el": "String",
                  "name:ga": "String",
                  "name:en": "String",
                  "name": "String",
                  "name:gd": "String",
                  "name:ja_kana": "String",
                  "name:is": "String",
                  "name:th": "String",
                  "name:latin": "String",
                  "name:sr-Latn": "String",
                  "name:et": "String",
                  "name:es": "String"
              },
              "minzoom": 0,
              "id": "waterway",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "class": "String",
                  "subclass": "String"
              },
              "minzoom": 0,
              "id": "landcover",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "class": "String"
              },
              "minzoom": 0,
              "id": "landuse",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "name:mt": "String",
                  "name:pt": "String",
                  "name:az": "String",
                  "name:ka": "String",
                  "name:rm": "String",
                  "name:ko": "String",
                  "name:kn": "String",
                  "name:ar": "String",
                  "name:cs": "String",
                  "rank": "Number",
                  "name_de": "String",
                  "name:ro": "String",
                  "name:it": "String",
                  "name_int": "String",
                  "name:lv": "String",
                  "name:pl": "String",
                  "name:de": "String",
                  "name:ca": "String",
                  "name:bg": "String",
                  "name:cy": "String",
                  "name:fi": "String",
                  "name:he": "String",
                  "name:da": "String",
                  "ele": "Number",
                  "name:tr": "String",
                  "name:fr": "String",
                  "name:mk": "String",
                  "name:nonlatin": "String",
                  "name:fy": "String",
                  "name:be": "String",
                  "name:zh": "String",
                  "name:sl": "String",
                  "name:nl": "String",
                  "name:ja": "String",
                  "name:lt": "String",
                  "name:no": "String",
                  "name:kk": "String",
                  "name:ko_rm": "String",
                  "name:ja_rm": "String",
                  "name:br": "String",
                  "name:bs": "String",
                  "name:lb": "String",
                  "name:la": "String",
                  "name:sk": "String",
                  "name:uk": "String",
                  "name:hy": "String",
                  "name:ru": "String",
                  "name:sv": "String",
                  "name_en": "String",
                  "name:hu": "String",
                  "name:hr": "String",
                  "name:sr": "String",
                  "name:sq": "String",
                  "name:el": "String",
                  "name:ga": "String",
                  "name:en": "String",
                  "name": "String",
                  "name:gd": "String",
                  "ele_ft": "Number",
                  "name:ja_kana": "String",
                  "name:is": "String",
                  "osm_id": "Number",
                  "name:th": "String",
                  "name:latin": "String",
                  "name:sr-Latn": "String",
                  "name:et": "String",
                  "name:es": "String"
              },
              "minzoom": 0,
              "id": "mountain_peak",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "class": "String"
              },
              "minzoom": 0,
              "id": "park",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "admin_level": "Number",
                  "disputed": "Number",
                  "maritime": "Number"
              },
              "minzoom": 0,
              "id": "boundary",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "class": "String"
              },
              "minzoom": 0,
              "id": "aeroway",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "brunnel": "String",
                  "ramp": "Number",
                  "class": "String",
                  "service": "String",
                  "oneway": "Number"
              },
              "minzoom": 0,
              "id": "transportation",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "render_min_height": "Number",
                  "render_height": "Number"
              },
              "minzoom": 0,
              "id": "building",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "name:mt": "String",
                  "name:pt": "String",
                  "name:az": "String",
                  "name:ka": "String",
                  "name:rm": "String",
                  "name:ko": "String",
                  "name:kn": "String",
                  "name:ar": "String",
                  "name:cs": "String",
                  "name_de": "String",
                  "name:ro": "String",
                  "name:it": "String",
                  "name_int": "String",
                  "name:ru": "String",
                  "name:pl": "String",
                  "name:ca": "String",
                  "name:lv": "String",
                  "name:bg": "String",
                  "name:cy": "String",
                  "name:fi": "String",
                  "name:he": "String",
                  "name:da": "String",
                  "name:de": "String",
                  "name:tr": "String",
                  "name:fr": "String",
                  "name:mk": "String",
                  "name:nonlatin": "String",
                  "name:fy": "String",
                  "name:be": "String",
                  "name:zh": "String",
                  "name:sr": "String",
                  "name:sl": "String",
                  "name:nl": "String",
                  "name:ja": "String",
                  "name:lt": "String",
                  "name:no": "String",
                  "name:kk": "String",
                  "name:ko_rm": "String",
                  "name:ja_rm": "String",
                  "name:br": "String",
                  "name:bs": "String",
                  "name:lb": "String",
                  "name:la": "String",
                  "name:sk": "String",
                  "name:uk": "String",
                  "name:hy": "String",
                  "name:sv": "String",
                  "name_en": "String",
                  "name:hu": "String",
                  "name:hr": "String",
                  "class": "String",
                  "name:sq": "String",
                  "name:el": "String",
                  "name:ga": "String",
                  "name:en": "String",
                  "name": "String",
                  "name:gd": "String",
                  "name:ja_kana": "String",
                  "name:is": "String",
                  "name:th": "String",
                  "name:latin": "String",
                  "name:sr-Latn": "String",
                  "name:et": "String",
                  "name:es": "String"
              },
              "minzoom": 0,
              "id": "water_name",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "name:mt": "String",
                  "name:pt": "String",
                  "name:az": "String",
                  "name:ka": "String",
                  "name:rm": "String",
                  "name:ko": "String",
                  "name:kn": "String",
                  "name:ar": "String",
                  "name:cs": "String",
                  "name_de": "String",
                  "name:ro": "String",
                  "name:it": "String",
                  "name_int": "String",
                  "name:ru": "String",
                  "name:pl": "String",
                  "name:ca": "String",
                  "name:lv": "String",
                  "name:bg": "String",
                  "name:cy": "String",
                  "name:fi": "String",
                  "name:he": "String",
                  "name:da": "String",
                  "name:de": "String",
                  "name:tr": "String",
                  "name:fr": "String",
                  "name:mk": "String",
                  "name:nonlatin": "String",
                  "name:fy": "String",
                  "name:be": "String",
                  "name:zh": "String",
                  "name:sr": "String",
                  "name:sl": "String",
                  "name:nl": "String",
                  "name:ja": "String",
                  "name:lt": "String",
                  "name:no": "String",
                  "name:kk": "String",
                  "name:ko_rm": "String",
                  "name:ja_rm": "String",
                  "name:br": "String",
                  "name:bs": "String",
                  "name:lb": "String",
                  "name:la": "String",
                  "name:sk": "String",
                  "name:uk": "String",
                  "name:hy": "String",
                  "name:sv": "String",
                  "name_en": "String",
                  "name:hu": "String",
                  "name:hr": "String",
                  "class": "String",
                  "name:sq": "String",
                  "network": "String",
                  "name:el": "String",
                  "name:ga": "String",
                  "name:en": "String",
                  "name": "String",
                  "name:gd": "String",
                  "ref": "String",
                  "name:ja_kana": "String",
                  "ref_length": "Number",
                  "name:is": "String",
                  "name:th": "String",
                  "name:latin": "String",
                  "name:sr-Latn": "String",
                  "name:et": "String",
                  "name:es": "String"
              },
              "minzoom": 0,
              "id": "transportation_name",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "name:mt": "String",
                  "name:pt": "String",
                  "name:az": "String",
                  "name:ka": "String",
                  "name:rm": "String",
                  "name:ko": "String",
                  "name:kn": "String",
                  "name:ar": "String",
                  "name:cs": "String",
                  "rank": "Number",
                  "name_de": "String",
                  "name:ro": "String",
                  "name:it": "String",
                  "name_int": "String",
                  "name:ru": "String",
                  "name:pl": "String",
                  "name:ca": "String",
                  "name:lv": "String",
                  "name:bg": "String",
                  "name:cy": "String",
                  "name:hr": "String",
                  "name:fi": "String",
                  "name:he": "String",
                  "name:da": "String",
                  "name:de": "String",
                  "name:tr": "String",
                  "name:fr": "String",
                  "name:mk": "String",
                  "name:nonlatin": "String",
                  "name:fy": "String",
                  "name:be": "String",
                  "name:zh": "String",
                  "name:sr": "String",
                  "name:sl": "String",
                  "name:nl": "String",
                  "name:ja": "String",
                  "name:ko_rm": "String",
                  "name:no": "String",
                  "name:kk": "String",
                  "capital": "Number",
                  "name:ja_rm": "String",
                  "name:br": "String",
                  "name:bs": "String",
                  "name:lb": "String",
                  "name:la": "String",
                  "name:sk": "String",
                  "name:uk": "String",
                  "name:hy": "String",
                  "name:sv": "String",
                  "name_en": "String",
                  "name:hu": "String",
                  "name:lt": "String",
                  "class": "String",
                  "name:sq": "String",
                  "name:el": "String",
                  "name:ga": "String",
                  "name:en": "String",
                  "name": "String",
                  "name:gd": "String",
                  "name:ja_kana": "String",
                  "name:is": "String",
                  "name:th": "String",
                  "name:latin": "String",
                  "name:sr-Latn": "String",
                  "name:et": "String",
                  "name:es": "String"
              },
              "minzoom": 0,
              "id": "place",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "housenumber": "String"
              },
              "minzoom": 0,
              "id": "housenumber",
              "description": ""
          }, {
              "maxzoom": 14,
              "fields": {
                  "name:mt": "String",
                  "name:pt": "String",
                  "name:az": "String",
                  "name:ka": "String",
                  "name:rm": "String",
                  "name:ko": "String",
                  "name:kn": "String",
                  "name:ar": "String",
                  "name:cs": "String",
                  "rank": "Number",
                  "name_de": "String",
                  "name:ro": "String",
                  "name:it": "String",
                  "name_int": "String",
                  "name:ru": "String",
                  "name:pl": "String",
                  "name:ca": "String",
                  "name:lv": "String",
                  "name:bg": "String",
                  "name:cy": "String",
                  "name:fi": "String",
                  "name:he": "String",
                  "name:da": "String",
                  "subclass": "String",
                  "name:de": "String",
                  "name:tr": "String",
                  "name:fr": "String",
                  "name:mk": "String",
                  "name:nonlatin": "String",
                  "name:fy": "String",
                  "name:be": "String",
                  "name:zh": "String",
                  "name:sr": "String",
                  "name:sl": "String",
                  "name:nl": "String",
                  "name:ja": "String",
                  "name:lt": "String",
                  "name:no": "String",
                  "name:kk": "String",
                  "name:ko_rm": "String",
                  "name:ja_rm": "String",
                  "name:br": "String",
                  "name:bs": "String",
                  "name:lb": "String",
                  "name:la": "String",
                  "name:sk": "String",
                  "name:uk": "String",
                  "name:hy": "String",
                  "name:sv": "String",
                  "name_en": "String",
                  "name:hu": "String",
                  "name:hr": "String",
                  "class": "String",
                  "name:sq": "String",
                  "name:el": "String",
                  "name:ga": "String",
                  "name:en": "String",
                  "name": "String",
                  "name:gd": "String",
                  "name:ja_kana": "String",
                  "name:is": "String",
                  "name:th": "String",
                  "name:latin": "String",
                  "name:sr-Latn": "String",
                  "name:et": "String",
                  "name:es": "String"
              },
              "minzoom": 0,
              "id": "poi",
              "description": ""
          } ],
          "zoom": 6,
        }
      },
      "glyphs": location.origin + '/map/fonts/{fontstack}/{range}.pbf',
      "layers": [
        {
          "id": "background",
          "type": "background",
          "paint": {"background-color": "rgb(253,237,231)"}
        },
        {
          "id": "park",
          "type": "fill",
          "source": "openmaptiles",
          "source-layer": "park",
          "filter": ["==", "$type", "Polygon"],
          "layout": {"visibility": "visible"},
          "paint": {"fill-color": "rgb(248, 230, 224)"}
        },
        {
          "id": "water",
          "type": "fill",
          "source": "openmaptiles",
          "source-layer": "water",
          "filter": [
            "all",
            ["==", "$type", "Polygon"],
            ["!=", "brunnel", "tunnel"]
          ],
          "layout": {"visibility": "visible"},
          "paint": {"fill-antialias": true, "fill-color": "rgb(232, 188, 176)"}
        },
        {
          "id": "landcover_ice_shelf",
          "type": "fill",
          "source": "openmaptiles",
          "source-layer": "landcover",
          "maxzoom": 8,
          "filter": [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "subclass", "ice_shelf"]
          ],
          "layout": {"visibility": "visible"},
          "paint": {"fill-color": "hsl(0, 0%, 98%)", "fill-opacity": 0.7}
        },
        {
          "id": "landcover_glacier",
          "type": "fill",
          "source": "openmaptiles",
          "source-layer": "landcover",
          "maxzoom": 8,
          "filter": [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "subclass", "glacier"]
          ],
          "layout": {"visibility": "visible"},
          "paint": {
            "fill-color": "hsl(0, 0%, 98%)",
            "fill-opacity": {"base": 1, "stops": [[0, 1], [8, 0.5]]}
          }
        },
        {
          "id": "landuse_residential",
          "type": "fill",
          "source": "openmaptiles",
          "source-layer": "landuse",
          "maxzoom": 16,
          "filter": [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "class", "residential"]
          ],
          "layout": {"visibility": "visible"},
          "paint": {
            "fill-color": "rgb(248, 230, 224)",
            "fill-opacity": {"base": 0.6, "stops": [[8, 0.8], [9, 0.6]]}
          }
        },
        {
          "id": "landcover_wood",
          "type": "fill",
          "source": "openmaptiles",
          "source-layer": "landcover",
          "minzoom": 10,
          "filter": ["all", ["==", "$type", "Polygon"], ["==", "class", "wood"]],
          "layout": {"visibility": "visible"},
          "paint": {
            "fill-color": "rgb(248,230,224)",
            "fill-opacity": {"base": 1, "stops": [[8, 0], [12, 1]]}
          }
        },
        {
          "id": "waterway",
          "type": "line",
          "source": "openmaptiles",
          "source-layer": "waterway",
          "filter": ["==", "$type", "LineString"],
          "layout": {"visibility": "visible"},
          "paint": {"line-color": "rgb(232, 188, 176)"}
        },
        {
          "id": "water_name",
          "type": "symbol",
          "source": "openmaptiles",
          "source-layer": "water_name",
          "filter": ["==", "$type", "LineString"],
          "layout": {
            "symbol-placement": "line",
            "symbol-spacing": 500,
            "text-field": "{name:latin}\n{name:nonlatin}",
            "text-font": ["Metropolis Medium Italic", "Noto Sans Italic"],
            "text-rotation-alignment": "map",
            "text-size": 12
          },
          "paint": {
            "text-color": "rgb(157,169,177)",
            "text-halo-blur": 1,
            "text-halo-color": "rgb(253,237,231)",
            "text-halo-width": 1
          }
        },
        {
          "id": "building",
          "type": "fill",
          "source": "openmaptiles",
          "source-layer": "building",
          "minzoom": 12,
          "paint": {
            "fill-antialias": true,
            "fill-color": "rgb(234, 234, 229)",
            "fill-outline-color": "rgb(219, 219, 218)"
          }
        },
        {
          "id": "tunnel_motorway_casing",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 6,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["all", ["==", "brunnel", "tunnel"], ["==", "class", "motorway"]]
          ],
          "layout": {
            "line-cap": "butt",
            "line-join": "miter",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "rgb(232, 188, 176)",
            "line-opacity": 1,
            "line-width": {"base": 1.4, "stops": [[5.8, 0], [6, 3], [20, 40]]}
          }
        },
        {
          "id": "tunnel_motorway_inner",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 6,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["all", ["==", "brunnel", "tunnel"], ["==", "class", "motorway"]]
          ],
          "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "rgb(234,234,234)",
            "line-width": {"base": 1.4, "stops": [[4, 2], [6, 1.3], [20, 30]]}
          }
        },
        {
          "id": "aeroway-taxiway",
          "type": "line",
          "metadata": {"mapbox:group": "1444849345966.4436"},
          "source": "openmaptiles",
          "source-layer": "aeroway",
          "minzoom": 12,
          "filter": ["all", ["in", "class", "taxiway"]],
          "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "rgb(242, 205, 195)",
            "line-opacity": 1,
            "line-width": {"base": 1.55, "stops": [[13, 1.8], [20, 20]]}
          }
        },
        {
          "id": "aeroway-runway-casing",
          "type": "line",
          "metadata": {"mapbox:group": "1444849345966.4436"},
          "source": "openmaptiles",
          "source-layer": "aeroway",
          "minzoom": 11,
          "filter": ["all", ["in", "class", "runway"]],
          "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "rgb(242, 205, 195)",
            "line-opacity": 1,
            "line-width": {"base": 1.5, "stops": [[11, 6], [17, 55]]}
          }
        },
        {
          "id": "aeroway-area",
          "type": "fill",
          "metadata": {"mapbox:group": "1444849345966.4436"},
          "source": "openmaptiles",
          "source-layer": "aeroway",
          "minzoom": 4,
          "filter": [
            "all",
            ["==", "$type", "Polygon"],
            ["in", "class", "runway", "taxiway"]
          ],
          "layout": {"visibility": "visible"},
          "paint": {
            "fill-color": "rgba(255, 255, 255, 1)",
            "fill-opacity": {"base": 1, "stops": [[13, 0], [14, 1]]}
          }
        },
        {
          "id": "aeroway-runway",
          "type": "line",
          "metadata": {"mapbox:group": "1444849345966.4436"},
          "source": "openmaptiles",
          "source-layer": "aeroway",
          "minzoom": 11,
          "filter": [
            "all",
            ["in", "class", "runway"],
            ["==", "$type", "LineString"]
          ],
          "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "rgba(255, 255, 255, 1)",
            "line-opacity": 1,
            "line-width": {"base": 1.5, "stops": [[11, 4], [17, 50]]}
          }
        },
        {
          "id": "road_area_pier",
          "type": "fill",
          "metadata": {},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "filter": ["all", ["==", "$type", "Polygon"], ["==", "class", "pier"]],
          "layout": {"visibility": "visible"},
          "paint": {"fill-antialias": true, "fill-color": "rgb(253,237,231)"}
        },
        {
          "id": "road_pier",
          "type": "line",
          "metadata": {},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "filter": ["all", ["==", "$type", "LineString"], ["in", "class", "pier"]],
          "layout": {"line-cap": "round", "line-join": "round"},
          "paint": {
            "line-color": "rgb(253,237,231)",
            "line-width": {"base": 1.2, "stops": [[15, 1], [17, 4]]}
          }
        },
        {
          "id": "highway_path",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "filter": ["all", ["==", "$type", "LineString"], ["==", "class", "path"]],
          "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "rgb(234, 234, 234)",
            "line-opacity": 0.9,
            "line-width": {"base": 1.2, "stops": [[13, 1], [20, 10]]}
          }
        },
        {
          "id": "highway_minor",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 8,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["in", "class", "minor", "service", "track"]
          ],
          "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "rgb(242, 205, 195)",
            "line-opacity": 0.9,
            "line-width": {"base": 1.55, "stops": [[13, 1.8], [20, 20]]}
          }
        },
        {
          "id": "highway_major_casing",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 11,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["in", "class", "primary", "secondary", "tertiary", "trunk"]
          ],
          "layout": {
            "line-cap": "butt",
            "line-join": "miter",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "rgb(232, 188, 176)",
            "line-dasharray": [12, 0],
            "line-width": {"base": 1.3, "stops": [[10, 3], [20, 23]]}
          }
        },
        {
          "id": "highway_major_inner",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 11,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["in", "class", "primary", "secondary", "tertiary", "trunk"]
          ],
          "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "#fff",
            "line-width": {"base": 1.3, "stops": [[10, 2], [20, 20]]}
          }
        },
        {
          "id": "highway_major_subtle",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "maxzoom": 11,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["in", "class", "primary", "secondary", "tertiary", "trunk"]
          ],
          "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
          },
          "paint": {"line-color": "rgb(244, 220, 211)", "line-width": 2}
        },
        {
          "id": "highway_motorway_casing",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 6,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            [
              "all",
              ["!in", "brunnel", "bridge", "tunnel"],
              ["==", "class", "motorway"]
            ]
          ],
          "layout": {
            "line-cap": "butt",
            "line-join": "miter",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "rgb(232, 188, 176)",
            "line-dasharray": [2, 0],
            "line-opacity": 1,
            "line-width": {"base": 1.4, "stops": [[5.8, 0], [6, 3], [20, 40]]}
          }
        },
        {
          "id": "highway_motorway_inner",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 6,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            [
              "all",
              ["!in", "brunnel", "bridge", "tunnel"],
              ["==", "class", "motorway"]
            ]
          ],
          "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
          },
          "paint": {
            "line-color": {
              "base": 1,
              "stops": [[5.8, "hsla(0, 0%, 85%, 0.53)"], [6, "#fff"]]
            },
            "line-width": {"base": 1.4, "stops": [[4, 2], [6, 1.3], [20, 30]]}
          }
        },
        {
          "id": "highway_motorway_subtle",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "maxzoom": 6,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["==", "class", "motorway"]
          ],
          "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "hsla(0, 0%, 85%, 0.53)",
            "line-width": {"base": 1.4, "stops": [[4, 2], [6, 1.3]]}
          }
        },
        {
          "id": "railway_transit",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 16,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["all", ["==", "class", "transit"], ["!in", "brunnel", "tunnel"]]
          ],
          "layout": {"line-join": "round", "visibility": "visible"},
          "paint": {"line-color": "#dddddd", "line-width": 3}
        },
        {
          "id": "railway_transit_dashline",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 16,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["all", ["==", "class", "transit"], ["!in", "brunnel", "tunnel"]]
          ],
          "layout": {"line-join": "round", "visibility": "visible"},
          "paint": {
            "line-color": "#fafafa",
            "line-dasharray": [3, 3],
            "line-width": 2
          }
        },
        {
          "id": "railway_service",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 16,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["all", ["==", "class", "rail"], ["has", "service"]]
          ],
          "layout": {"line-join": "round", "visibility": "visible"},
          "paint": {"line-color": "#dddddd", "line-width": 3}
        },
        {
          "id": "railway_service_dashline",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 16,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["==", "class", "rail"],
            ["has", "service"]
          ],
          "layout": {"line-join": "round", "visibility": "visible"},
          "paint": {
            "line-color": "#fafafa",
            "line-dasharray": [3, 3],
            "line-width": 2
          }
        },
        {
          "id": "railway",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 13,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["all", ["!has", "service"], ["==", "class", "rail"]]
          ],
          "layout": {"line-join": "round", "visibility": "visible"},
          "paint": {
            "line-color": "#dddddd",
            "line-width": {"base": 1.3, "stops": [[16, 3], [20, 7]]}
          }
        },
        {
          "id": "railway_dashline",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 13,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["all", ["!has", "service"], ["==", "class", "rail"]]
          ],
          "layout": {"line-join": "round", "visibility": "visible"},
          "paint": {
            "line-color": "#fafafa",
            "line-dasharray": [3, 3],
            "line-width": {"base": 1.3, "stops": [[16, 2], [20, 6]]}
          }
        },
        {
          "id": "highway_motorway_bridge_casing",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 6,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["all", ["==", "brunnel", "bridge"], ["==", "class", "motorway"]]
          ],
          "layout": {
            "line-cap": "butt",
            "line-join": "miter",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "rgb(232, 188, 176)",
            "line-dasharray": [2, 0],
            "line-opacity": 1,
            "line-width": {"base": 1.4, "stops": [[5.8, 0], [6, 5], [20, 45]]}
          }
        },
        {
          "id": "highway_motorway_bridge_inner",
          "type": "line",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation",
          "minzoom": 6,
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["all", ["==", "brunnel", "bridge"], ["==", "class", "motorway"]]
          ],
          "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
          },
          "paint": {
            "line-color": {
              "base": 1,
              "stops": [[5.8, "hsla(0, 0%, 85%, 0.53)"], [6, "#fff"]]
            },
            "line-width": {"base": 1.4, "stops": [[4, 2], [6, 1.3], [20, 30]]}
          }
        },
        {
          "id": "highway_name_other",
          "type": "symbol",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation_name",
          "filter": [
            "all",
            ["!=", "class", "motorway"],
            ["==", "$type", "LineString"]
          ],
          "layout": {
            "symbol-placement": "line",
            "symbol-spacing": 350,
            "text-field": "{name:latin} {name:nonlatin}",
            "text-font": ["albatross"],
            "text-max-angle": 30,
            "text-pitch-alignment": "viewport",
            "text-rotation-alignment": "map",
            "text-size": 10,
            "text-transform": "uppercase",
            "visibility": "visible"
          },
          "paint": {
            "text-color": "#bbb",
            "text-halo-blur": 1,
            "text-halo-color": "#fff",
            "text-halo-width": 2,
            "text-translate": [0, 0]
          }
        },
        {
          "id": "highway_name_motorway",
          "type": "symbol",
          "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"},
          "source": "openmaptiles",
          "source-layer": "transportation_name",
          "filter": [
            "all",
            ["==", "$type", "LineString"],
            ["==", "class", "motorway"]
          ],
          "layout": {
            "symbol-placement": "line",
            "symbol-spacing": 350,
            "text-field": "{ref}",
            "text-font": ["albatross"],
            "text-pitch-alignment": "viewport",
            "text-rotation-alignment": "viewport",
            "text-size": 10,
            "visibility": "visible"
          },
          "paint": {
            "text-color": "rgb(60, 60, 60)",
            "text-halo-blur": 1,
            "text-halo-color": "hsl(0, 0%, 100%)",
            "text-halo-width": 1,
            "text-translate": [0, 2]
          }
        },
        {
          "id": "boundary_state",
          "type": "line",
          "metadata": {"mapbox:group": "a14c9607bc7954ba1df7205bf660433f"},
          "source": "openmaptiles",
          "source-layer": "boundary",
          "filter": ["==", "admin_level", 4],
          "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "rgb(228, 35, 32)",
            "line-dasharray": [2, 2],
            "line-opacity": 1,
            "line-width": {"base": 1.3, "stops": [[3, 1], [22, 15]]}
          }
        },
        {
          "id": "boundary_state_fix",
          "type": "line",
          "source": "fix",
          "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
          },
          "paint": {
            "line-color": "rgb(228, 35, 32)",
            "line-dasharray": [2, 2],
            "line-opacity": 1,
            "line-width": {"base": 1.3, "stops": [[3, 1], [22, 15]]}
          }
        },
        {
          "id": "boundary_country_z0-4",
          "type": "line",
          "metadata": {"mapbox:group": "a14c9607bc7954ba1df7205bf660433f"},
          "source": "openmaptiles",
          "source-layer": "boundary",
          "maxzoom": 5,
          "filter": ["all", ["==", "admin_level", 2], ["!has", "claimed_by"]],
          "layout": {"line-cap": "round", "line-join": "round"},
          "paint": {
            "line-color": "rgb(228, 35, 32)",
            "line-opacity": 1,
            "line-width": {"base": 1.1, "stops": [[3, 1], [22, 20]]}
          }
        },
        {
          "id": "boundary_country_z5-",
          "type": "line",
          "metadata": {"mapbox:group": "a14c9607bc7954ba1df7205bf660433f"},
          "source": "openmaptiles",
          "source-layer": "boundary",
          "minzoom": 5,
          "filter": ["==", "admin_level", 2],
          "layout": {"line-cap": "round", "line-join": "round"},
          "paint": {
            "line-color": "rgb(228, 35, 32)",
            "line-opacity": 1,
            "line-width": {"base": 1.1, "stops": [[3, 1], [22, 20]]}
          }
        },
        {
          "id": "place_other",
          "type": "symbol",
          "metadata": {"mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"},
          "source": "openmaptiles",
          "source-layer": "place",
          "maxzoom": 14,
          "filter": [
            "all",
            [
              "in",
              "class",
              "continent",
              "hamlet",
              "neighbourhood",
              "isolated_dwelling"
            ],
            ["==", "$type", "Point"]
          ],
          "layout": {
            "text-anchor": "center",
            "text-field": "{name:latin}\n{name:nonlatin}",
            "text-font": ["albatross"],
            "text-justify": "center",
            "text-offset": [0.5, 0],
            "text-size": 10,
            "text-transform": "uppercase",
            "visibility": "visible"
          },
          "paint": {
            "text-color": "rgb(60, 60, 60)",
            "text-halo-blur": 1,
            "text-halo-color": "rgb(253,237,231)",
            "text-halo-width": 1
          }
        },
        {
          "id": "place_suburb",
          "type": "symbol",
          "metadata": {"mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"},
          "source": "openmaptiles",
          "source-layer": "place",
          "maxzoom": 15,
          "filter": ["all", ["==", "$type", "Point"], ["==", "class", "suburb"]],
          "layout": {
            "text-anchor": "center",
            "text-field": "{name:latin}\n{name:nonlatin}",
            "text-font": ["albatross"],
            "text-justify": "center",
            "text-offset": [0.5, 0],
            "text-size": 10,
            "text-transform": "uppercase",
            "visibility": "visible"
          },
          "paint": {
            "text-color": "rgb(60, 60, 60)",
            "text-halo-blur": 1,
            "text-halo-color": "rgb(253,237,231)",
            "text-halo-width": 1
          }
        },
        {
          "id": "place_village",
          "type": "symbol",
          "metadata": {"mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"},
          "source": "openmaptiles",
          "source-layer": "place",
          "maxzoom": 14,
          "filter": ["all", ["==", "$type", "Point"], ["==", "class", "village"]],
          "layout": {
            "icon-size": 0.4,
            "text-anchor": "left",
            "text-field": "{name:latin}\n{name:nonlatin}",
            "text-font": ["albatross"],
            "text-justify": "left",
            "text-offset": [0, 0.3],
            "text-size": 12,
            "text-transform": "uppercase",
            "visibility": "visible"
          },
          "paint": {
            "icon-opacity": 0.7,
            "text-color": "rgb(60, 60, 60)",
            "text-halo-blur": 1,
            "text-halo-color": "rgb(253,237,231)",
            "text-halo-width": 1
          }
        },
        {
          "id": "place_town",
          "type": "symbol",
          "metadata": {"mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"},
          "source": "openmaptiles",
          "source-layer": "place",
          "maxzoom": 15,
          "filter": ["all", ["==", "$type", "Point"], ["==", "class", "town"]],
          "layout": {
            "icon-image": {"base": 1, "stops": [[0, "circle-11"], [8, ""]]},
            "icon-size": 0.4,
            "text-anchor": {"base": 1, "stops": [[0, "left"], [8, "center"]]},
            "text-field": "{name:latin}\n{name:nonlatin}",
            "text-font": ["albatross"],
            "text-justify": "left",
            "text-offset": [0, 0.4],
            "text-size": 16,
            "text-transform": "uppercase",
            "visibility": "visible"
          },
          "paint": {
            "icon-opacity": 0.7,
            "text-color": "rgb(60, 60, 60)",
            "text-halo-blur": 1,
            "text-halo-color": "rgb(253,237,231)",
            "text-halo-width": 1
          }
        },
        {
          "id": "place_city",
          "type": "symbol",
          "metadata": {"mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"},
          "source": "openmaptiles",
          "source-layer": "place",
          "maxzoom": 14,
          "filter": [
            "all",
            ["==", "$type", "Point"],
            ["all", ["!=", "capital", 2], ["==", "class", "city"], [">", "rank", 3]]
          ],
          "layout": {
            "icon-image": {"base": 1, "stops": [[0, "circle-11"], [8, ""]]},
            "icon-size": 0.4,
            "text-anchor": {"base": 1, "stops": [[0, "left"], [8, "center"]]},
            "text-field": "{name:latin}\n{name:nonlatin}",
            "text-font": ["albatross"],
            "text-justify": "left",
            "text-offset": [0, 0.4],
            "text-size": 20,
            "text-transform": "uppercase",
            "visibility": "visible"
          },
          "paint": {
            "icon-opacity": 0.7,
            "text-color": "rgb(60, 60, 60)",
            "text-halo-blur": 1,
            "text-halo-color": "rgb(253,237,231)",
            "text-halo-width": 1
          }
        },
        {
          "id": "place_capital",
          "type": "symbol",
          "metadata": {"mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"},
          "source": "openmaptiles",
          "source-layer": "place",
          "maxzoom": 12,
          "filter": [
            "all",
            ["==", "$type", "Point"],
            ["all", ["==", "capital", 2], ["==", "class", "city"]]
          ],
          "layout": {
            "icon-image": {"base": 1, "stops": [[0, "star-11"], [8, ""]]},
            "icon-size": 1,
            "text-anchor": {"base": 1, "stops": [[0, "left"], [8, "center"]]},
            "text-field": "{name:latin}\n{name:nonlatin}",
            "text-font": ["albatross"],
            "text-justify": "left",
            "text-offset": [0.5, 0.2],
            "text-size": 14,
            "text-transform": "uppercase",
            "visibility": "visible"
          },
          "paint": {
            "icon-opacity": 0.7,
            "text-color": "rgb(60, 60, 60)",
            "text-halo-blur": 1,
            "text-halo-color": "rgb(253,237,231)",
            "text-halo-width": 1
          }
        },
        {
          "id": "place_city_large",
          "type": "symbol",
          "metadata": {"mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"},
          "source": "openmaptiles",
          "source-layer": "place",
          "maxzoom": 12,
          "filter": [
            "all",
            ["==", "$type", "Point"],
            [
              "all",
              ["!=", "capital", 2],
              ["<=", "rank", 3],
              ["==", "class", "city"]
            ]
          ],
          "layout": {
            "icon-image": {"base": 1, "stops": [[0, "circle-11"], [8, ""]]},
            "icon-size": 0.4,
            "text-anchor": {"base": 1, "stops": [[0, "left"], [8, "center"]]},
            "text-field": "{name:latin}\n{name:nonlatin}",
            "text-font": ["albatross"],
            "text-justify": "left",
            "text-offset": [0.5, 0.2],
            "text-size": 14,
            "text-transform": "uppercase",
            "visibility": "visible"
          },
          "paint": {
            "icon-opacity": 0.7,
            "text-color": "rgb(60, 60, 60)",
            "text-halo-blur": 1,
            "text-halo-color": "rgb(253,237,231)",
            "text-halo-width": 1
          }
        },
        {
          "id": "place_state",
          "type": "symbol",
          "metadata": {"mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"},
          "source": "openmaptiles",
          "source-layer": "place",
          "maxzoom": 12,
          "filter": ["all", ["==", "$type", "Point"], ["==", "class", "state"]],
          "layout": {
            "text-field": "{name:latin}\n{name:nonlatin}",
            "text-font": ["albatross"],
            "text-size": 10,
            "text-transform": "uppercase",
            "visibility": "visible"
          },
          "paint": {
            "text-color": "rgb(113, 129, 144)",
            "text-halo-blur": 1,
            "text-halo-color": "rgb(253,237,231)",
            "text-halo-width": 1
          }
        },
        {
          "id": "place_country_other",
          "type": "symbol",
          "metadata": {"mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"},
          "source": "openmaptiles",
          "source-layer": "place",
          "maxzoom": 8,
          "filter": [
            "all",
            ["==", "$type", "Point"],
            ["==", "class", "country"],
            ["!has", "iso_a2"]
          ],
          "layout": {
            "text-field": "{name:latin}",
            "text-font": ["Metropolis Light Italic", "Noto Sans Italic"],
            "text-size": {"base": 1, "stops": [[0, 9], [6, 11]]},
            "text-transform": "uppercase",
            "visibility": "visible"
          },
          "paint": {
            "text-color": {
              "base": 1,
              "stops": [[3, "rgb(157,169,177)"], [4, "rgb(153, 153, 153)"]]
            },
            "text-halo-color": "rgba(236,236,234,0.7)",
            "text-halo-width": 1.4
          }
        },
        {
          "id": "place_country_minor",
          "type": "symbol",
          "metadata": {"mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"},
          "source": "openmaptiles",
          "source-layer": "place",
          "maxzoom": 8,
          "filter": [
            "all",
            ["==", "$type", "Point"],
            ["==", "class", "country"],
            [">=", "rank", 2],
            ["has", "iso_a2"]
          ],
          "layout": {
            "text-field": "{name:latin}",
            "text-font": ["albatross"],
            "text-size": {"base": 1, "stops": [[0, 10], [6, 12]]},
            "text-transform": "uppercase",
            "visibility": "visible"
          },
          "paint": {
            "text-color": {
              "base": 1,
              "stops": [[3, "rgb(157,169,177)"], [4, "rgb(153, 153, 153)"]]
            },
            "text-halo-color": "rgba(236,236,234,0.7)",
            "text-halo-width": 1.4
          }
        },
        {
          "id": "place_country_major",
          "type": "symbol",
          "metadata": {"mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"},
          "source": "openmaptiles",
          "source-layer": "place",
          "maxzoom": 6,
          "filter": [
            "all",
            ["==", "$type", "Point"],
            ["<=", "rank", 1],
            ["==", "class", "country"],
            ["has", "iso_a2"]
          ],
          "layout": {
            "text-anchor": "center",
            "text-field": "{name:latin}",
            "text-font": ["albatross"],
            "text-size": {"base": 1.4, "stops": [[0, 10], [3, 12], [4, 14]]},
            "text-transform": "uppercase",
            "visibility": "visible"
          },
          "paint": {
            "text-color": {
              "base": 1,
              "stops": [[3, "rgb(157,169,177)"], [4, "rgb(153, 153, 153)"]]
            },
            "text-halo-color": "rgba(236,236,234,0.7)",
            "text-halo-width": 1.4
          }
        }
      ],
      "id": "basic"
    }

    return json;
  }
}
