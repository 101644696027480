<template>
    <div class="content" v-bind:class="{ opened: isOpened }">
        <button class="content__close" @click="close">&times;</button>
        <div class="content__container" v-if="isOpened">
            <header class="content__header">
                <h1 class="content__title">{{ title[this.$i18n.locale] }}</h1>
            </header>
            <div class="content__text text-content" v-html="text[this.$i18n.locale]">
            </div>
        </div>
        <div class="content__open" v-else>
            <button class="button" @click="open">{{ $t( 'navigation.information' ) }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Content',
    props: ['title', 'text', 'cta', 'opened'],
    data() {
        return {
            openedContent: this.opened
        }
    },
    computed: {
        isOpened: function() {
            return this.openedContent;
        }
    },
    mounted() {},
    unmounted() {},
    methods: {
        open() {
            this.openedContent = true;
        },
        close() {
            this.openedContent = false;
        },
    },
};
</script>

<style lang="scss">
.content {
    position: absolute;
    top: $base-gap * 5;
    right: $base-gap * 5;
    background-color: $color-red-100;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba($color-red-400, 0.3);
    display: flex;

    &.opened {
        width: 700px;
        height: 600px;
    }

    &__close {
        @include base-title;
        position: absolute;
        top: $base-gap * 2;
        right: $base-gap * 2;
        background-color: transparent;
        appearance: none;
        color: $color-red-400;
        font-size: 96px;
        line-height: 1em;
        padding: 0;
        margin: 0;
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        display: none;

        .opened & {
            display: flex;
        }
    }

    &__container {
        overflow-y: auto;
        width: 100%;
        padding: $base-gap * 4 $base-gap * 8 $base-gap * 4 $base-gap * 4;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 0;
            height: 0;
            display: none;
        }
    }

    &__header {
        margin-bottom: $base-gap * 2;
    }

    &__title {
        @include title;
        text-transform: uppercase;
        max-width: 600px;
        color: $color-red-400;
    }

    &__text {
        font-weight: 300;
    }

    &__cta {
        margin-top: $base-gap * 5;
    }
}
</style>
