import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'

const messages = {
    cz: {
        navigation: {
            map: 'Mapa',
            topics: 'Okruhy',
            information: 'Informace',
            back: 'Zpět do mapy'
        },
        topics: {
            choose: 'Vybrat okruh',
            back: 'Zpět na témata'
        },
        places: {
            back: 'Zpět na výpis míst'
        },
        sources: {
            title: 'Zdroje obrazové dokumentace'
        }
    },
    de: {
        navigation: {
            map: 'Plan',
            topics: 'Themas',
            information: 'Informationen',
            back: 'Zurück zum Plan'
        },
        topics: {
            choose: 'Thema auswählen',
            back: 'Zurück zum Thema'
        },
        places: {
            back: 'Zurück zur Liste der Plätze'
        },
        sources: {
            title: 'Quellen der Bilddokumentation'
        }
    },
    en: {
        navigation: {
            map: 'Map',
            topics: 'Topics',
            information: 'Information',
            back: 'Back to map'
        },
        topics: {
            choose: 'Choose topic',
            back: 'Back to topics'
        },
        places: {
            back: 'Back to place list'
        },
        sources: {
            title: 'Sources of pictorial documents'
        }
    }
}

const i18n = createI18n({
    locale: 'cz',
    fallbackLocale: 'cz',
    messages
})

createApp(App).use(store).use(router).use(i18n).mount('#app')
